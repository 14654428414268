import styled, { css } from 'styled-components';

interface IPropsGrid {
  numberOfColumns: number;
}

export const Container = styled.div<IPropsGrid>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  gap: 1rem;

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  }

  ${({ numberOfColumns }) => css`
    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(${numberOfColumns}, minmax(2rem, 1fr));
    }
  `}

  @media screen and (max-width: 768px) {
    gap: 0.8rem;
  }

  @media screen and (max-width: 468px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 0.8rem;
  }
`;
