import styled, { css, keyframes } from 'styled-components';

interface ISidebarStatus {
  widthSidebar: string;
}

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-255px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const SidebarNav = styled.div<ISidebarStatus>`
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  animation: ${appearFromLeft} 0.3s ease-in-out;

  ${({ theme, widthSidebar }) => css`
    background: ${theme.title === 'dark'
      ? theme.colors.black
      : theme.colors.blueGradient};

    flex: 0 0 ${widthSidebar};
    max-width: ${widthSidebar};
    min-width: ${widthSidebar};
    width: ${widthSidebar};
  `}
`;

export const SidebarHeader = styled.div`
  a {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      margin-left: 12px;
      font-size: 1.5rem;

      ${({ theme }) => css`
        color: ${theme.colors.ice};
      `}
    }

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const SidebarMenu = styled.div`
  flex: 1 1 0%;
  overflow: hidden auto;

  scrollbar-width: thin;

  ${({ theme }) => css`
    /* Works on Firefox */
    scrollbar-color: ${theme.colors.darkBlue} ${theme.colors.ice};

    /* Works on Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 6px;
      background: ${theme.colors.ice};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 6px;
      border: 3px solid ${theme.colors.darkBlue};
    }
  `}
`;

export const SidebarFooter = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;

  filter: brightness(1.1);

  svg {
    cursor: pointer;

    ${({ theme }) => css`
      color: ${theme.colors.ice};
    `}

    filter: brightness(0.8);

    :hover {
      filter: brightness(1);
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
