import { useRef, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiLogIn, FiMail } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useTheme } from 'hooks/theme';

import { api } from 'services/api';

import getValidationErrors from 'utils/getValidationErrors';

import Input from 'components/Input';
import Button from 'components/Button';

import logoInsight from 'assets/images/logo.svg';
import backgroundDark from 'assets/images/login-background-dark.svg';
import backgroundLight from 'assets/images/login-background-light.svg';

import { Container, Content, AnimationContainer, Background } from './styles';

interface IForgotPasswordFormData {
  email: string;
}

export default function ForgotPassword() {
  const formRef = useRef<FormHandles>(null);

  const { theme } = useTheme();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (data: IForgotPasswordFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('Digite um email válido'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      await api.post('/password/forgot', {
        email: data.email,
      });

      toast.success('Quase lá, dê uma checada em seu e-mail :)');
      history.push('/');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        toast.error('Usuário não encontrado!', { delay: 400 });
      }
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      <Background>
        <img
          src={theme.title === 'dark' ? backgroundDark : backgroundLight}
          alt="background-forgot-password"
        />
      </Background>
      <Content>
        <AnimationContainer>
          <img src={logoInsight} alt="logo-insight" width={350} height={124} />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Recuperar senha</h1>

            <Input
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              inputSize="1rem"
            />

            <Button loading={loading} type="submit">
              Recuperar
            </Button>
          </Form>

          <Link to="/">
            <FiLogIn />
            Voltar ao login
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
}
