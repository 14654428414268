/* eslint-disable @typescript-eslint/no-unused-vars */
import { createRef, useMemo, useState, useEffect, forwardRef } from 'react';
import {
  MdAddCircle,
  MdArrowDownward,
  MdCheck,
  MdChevronLeft,
  MdChevronRight,
  MdChromeReaderMode,
  MdClear,
  MdDelete,
  MdEdit,
  MdFileDownload,
  MdFilterList,
  MdFirstPage,
  MdLastPage,
  MdRemove,
  MdSearch,
  MdViewColumn,
} from 'react-icons/md';
import MaterialTable, {
  Column,
  Action,
  Icons,
  MTableBody,
} from '@material-table/core';
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import { useTheme } from 'hooks/theme';

import { ITableActions } from 'models/ITableActions';

import { TableCell, TableFooter, TableRow } from '@material-ui/core';
import { Container } from './styles';

type RowData = Column<RowData[]>;

interface ITableProps {
  componentData: RowData[];
  columnsData: Column<RowData>[];
  tableActions?: ITableActions;
  title?: string;
  searchable?: boolean;
  positionTextColumn?: 'right' | 'left' | 'center';
  boxShadow?: string;
  nameActionCustom?: string;
  height?: string;
  isCreateActionDisabled?: boolean;
  isFiltering?: boolean;
  isGrouping?: boolean;
  footer?: string[];
}

const Table = ({
  componentData,
  columnsData,
  tableActions,
  nameActionCustom,
  title,
  searchable,
  boxShadow = '0.25',
  positionTextColumn = 'right',
  height = 'calc(100vh - 20rem)',
  isCreateActionDisabled = false,
  isFiltering = false,
  isGrouping = false,
  footer = [],
}: ITableProps) => {
  const { theme } = useTheme();
  const tableRef = createRef();

  const [actions, setActions] = useState<Action<RowData>[]>([]);
  const [showTitle, setShowTitle] = useState(true);

  const tableIcons: Icons = {
    Check: forwardRef((props, ref) => <MdCheck {...props} />),
    Clear: forwardRef((props, ref) => <MdClear {...props} />),
    DetailPanel: forwardRef((props, ref) => <MdChevronRight {...props} />),
    Export: forwardRef((props, ref) => <MdFileDownload {...props} />),
    Filter: forwardRef((props, ref) => <MdFilterList {...props} />),
    FirstPage: forwardRef((props, ref) => <MdFirstPage {...props} />),
    LastPage: forwardRef((props, ref) => <MdLastPage {...props} />),
    NextPage: forwardRef((props, ref) => <MdChevronRight {...props} />),
    PreviousPage: forwardRef((props, ref) => <MdChevronLeft {...props} />),
    ResetSearch: forwardRef((props, ref) => <MdClear {...props} />),
    Search: forwardRef((props, ref) => <MdSearch {...props} size="1rem" />),
    SortArrow: forwardRef((props, ref) => <MdArrowDownward {...props} />),
    ThirdStateCheck: forwardRef((props, ref) => <MdRemove {...props} />),
    ViewColumn: forwardRef((props, ref) => <MdViewColumn {...props} />),
  };

  const tableTheme = useMemo(
    () =>
      createMuiTheme({
        overrides: {
          MuiPaper: {
            elevation2: {
              background: theme.colors.backgroundBox,
              padding: '1rem',
              boxShadow: `0px 4px 4px rgba(0, 0, 0, ${boxShadow})`,
              borderRadius: '0.5rem',
              zIndex: 0,
            },
          },
          MuiTypography: {
            h6: {
              fontFamily: '"Poppins", sans-serif',
              fontWeight: 600,
              fontSize: '1rem',
              color:
                theme.title === 'dark'
                  ? theme.colors.ice
                  : theme.colors.darkGray,
            },
          },
          MuiInputBase: {
            root: {
              color: theme.colors.cyan,
            },
          },
          MuiTableCell: {
            head: {
              background: `${theme.colors.backgroundIndex} !important`,
              color:
                theme.title === 'dark'
                  ? theme.colors.ice
                  : theme.colors.darkGray,
              fontFamily: '"Poppins", sans-serif',
              fontSize: '.9rem',
            },
            footer: {
              background: `${theme.colors.backgroundIndex} !important`,
              color:
                theme.title === 'dark'
                  ? theme.colors.ice
                  : theme.colors.darkGray,
              fontFamily: '"Poppins", sans-serif',
              fontSize: '.75rem',
              fontWeight: 'bold',
            },
            alignRight: {
              textAlign: positionTextColumn,
            },
          },
          MuiIconButton: {
            colorInherit: {
              color:
                theme.title === 'dark'
                  ? theme.colors.ice
                  : theme.colors.darkGray,
            },
          },
          MuiTableBody: {
            root: {
              color:
                theme.title === 'dark'
                  ? theme.colors.ice
                  : theme.colors.darkGray,
              fontWeight: 600,
              fontSize: '0.75rem',
              zIndex: 0,
            },
          },
          MuiTablePagination: {
            select: {
              fontFamily: '"Poppins", sans-serif',
              fontWeight: 500,
              color:
                theme.title === 'dark'
                  ? theme.colors.ice
                  : theme.colors.darkGray,
            },
            menuItem: {
              fontFamily: '"Poppins", sans-serif',
              fontWeight: 500,
              color:
                theme.title === 'dark'
                  ? theme.colors.ice
                  : theme.colors.darkGray,
            },
          },
        },
        palette: {
          type: theme.title === 'dark' ? 'dark' : 'light',
        },
      }),
    [theme],
  );

  useEffect(() => {
    if (tableActions) {
      setActions([
        {
          hidden: !tableActions.create,
          icon: () => <MdAddCircle color={theme.colors.cyan} />,
          tooltip: 'Novo',
          isFreeAction: true,
          disabled: isCreateActionDisabled,
          onClick: () => {
            if (tableActions.create) tableActions.create();
          },
        },
        {
          icon: () => <MdEdit color={theme.colors.cyan} size="1.2rem" />,
          iconProps: {
            color: 'primary',
          },
          tooltip: 'Editar',
          onClick: (_event, rowData) => tableActions.update(rowData),
        },
        {
          icon: () => <MdDelete color={theme.colors.lightRed} size="1.2rem" />,
          iconProps: {
            color: 'error',
          },
          tooltip: 'Excluir',
          onClick: (_event, rowData) => tableActions.delete(rowData),
        },
        {
          hidden: !nameActionCustom,
          icon: () => (
            <MdChromeReaderMode color={theme.colors.yellow} size="1.2rem" />
          ),
          iconProps: {
            color: 'error',
          },
          tooltip: nameActionCustom,
          onClick: (_event, rowData) => {
            if (tableActions.custom) tableActions.custom(rowData);
          },
        },
      ]);
    }

    if (!title) setShowTitle(false);
  }, [isCreateActionDisabled]);

  return (
    <>
      <ThemeProvider theme={tableTheme}>
        <Container>
          <MaterialTable
            title={title}
            tableRef={tableRef}
            columns={columnsData}
            data={componentData || []}
            icons={tableIcons}
            options={{
              sorting: true,
              exportAllData: true,
              loadingType: 'linear',
              pageSize: 10,
              pageSizeOptions: [5, 10, 50],
              showTitle,
              search: searchable,
              searchFieldAlignment: 'left',
              maxBodyHeight: height,
              minBodyHeight: height,
              doubleHorizontalScroll: false,
              paginationType: 'stepped',
              emptyRowsWhenPaging: false,
              filtering: isFiltering,
              grouping: isGrouping,
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'Nenhum registro para exibir...',
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar...',
              },
              pagination: {
                labelRowsSelect: 'linhas',
                labelDisplayedRows: '{count} de {from}-{to}',
                firstTooltip: 'Primeira página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Próxima página',
                lastTooltip: 'Última página',
              },
              header: {
                actions: 'AÇÕES',
              },
              grouping: {
                placeholder: 'Arraste os cabeçalhos aqui para agrupar por',
                groupedBy: 'Agrupado por:',
              },
            }}
            actions={actions}
            components={{
              Body: props => (
                <>
                  <TableFooter>
                    <TableRow>
                      {footer.map(item => (
                        <TableCell>{item}</TableCell>
                      ))}
                    </TableRow>
                  </TableFooter>
                  <MTableBody {...props} />
                </>
              ),
            }}
            renderSummaryRow={({ data, index, columns, column, currentData }) =>
              0
            }
          />
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Table;
