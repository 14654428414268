import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  max-width: 460px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Content = styled(Form)`
  section {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 16px;

    @media (max-width: 768px) {
      grid-auto-flow: row;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;

  span {
    color: red;
  }

  div {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 1rem;

    @media (max-width: 768px) {
      grid-auto-flow: row;
      gap: 0;
    }
  }
`;
