import { lazy } from 'react';

import { IRouteProperties } from 'models/IRoutes';

const Home = lazy(() => import('pages/Home'));

const BusinessAnalysis = lazy(
  () => import('pages/Modules/Commercial/BusinessAnalysis'),
);

const GoalAnalysis = lazy(
  () => import('pages/Modules/Commercial/GoalAnalysis'),
);

const CriticalAnalysis = lazy(
  () => import('pages/Modules/Commercial/CriticalAnalysis'),
);

const MediumTerm = lazy(() => import('pages/Modules/Commercial/MediumTerm'));

const BoardPanel = lazy(() => import('pages/Modules/Commercial/BoardPanel'));

const OpenTitles = lazy(() => import('pages/Modules/Financial/OpenTitle'));

export const modulesRoutes: IRouteProperties[] = [
  {
    component: Home,
    exact: true,
    path: '/home',
  },
  {
    component: CriticalAnalysis,
    exact: true,
    path: '/comercial/analise-critica',
  },
  {
    component: GoalAnalysis,
    exact: true,
    path: '/comercial/analise-meta',
  },
  {
    component: BusinessAnalysis,
    exact: true,
    path: '/comercial/analise',
  },
  {
    component: MediumTerm,
    exact: true,
    path: '/comercial/prazo-medio',
  },
  {
    component: BoardPanel,
    exact: true,
    path: '/comercial/painel-bordo',
  },
  {
    component: OpenTitles,
    exact: true,
    path: '/financial/open-titles',
  },
];
