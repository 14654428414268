import Configurations from 'pages/Configurations';
import Company from 'pages/Configurations/Company';
import Module from 'pages/Configurations/Module';
import User from 'pages/Configurations/User';
import Application from 'pages/Configurations/Application';
import AccessGroup from 'pages/Configurations/AccessGroup';

import { IRouteProperties } from 'models/IRoutes';

export const configurationsRoutes: IRouteProperties[] = [
  {
    component: Configurations,
    exact: true,
    path: '/configurations',
    rolePermissions: ['Administrador', 'Moderador'],
  },
  {
    component: Company,
    exact: true,
    path: '/configurations/company',
    rolePermissions: ['Administrador'],
  },
  {
    component: Module,
    exact: true,
    path: '/configurations/module',
    rolePermissions: ['Administrador'],
  },
  {
    component: User,
    exact: true,
    path: '/configurations/user',
    rolePermissions: ['Administrador', 'Moderador'],
  },
  {
    component: Application,
    exact: true,
    path: '/configurations/application',
    rolePermissions: ['Administrador'],
  },
  {
    component: AccessGroup,
    exact: true,
    path: '/configurations/access-group',
    rolePermissions: ['Administrador', 'Moderador'],
  },
];
