import styled from 'styled-components';
import { Form } from '@unform/web';

export const Content = styled(Form)`
  section {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 220px));
    gap: 1rem;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(140px, 220px));
      gap: 0.5rem;
    }

    @media (max-width: 468px) {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;

  span {
    color: red;
  }

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 2fr;
  gap: 1rem;

  @media (max-width: 768px) {
    grid-auto-flow: row;
    gap: 0;
  }
`;
