import styled, { css } from 'styled-components';
import { Form } from '@unform/web';

type StatusColor = {
  statusColor: string;
};

export const Container = styled.div``;

export const Content = styled(Form)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 1rem;

  margin-top: 2rem;
`;

export const ButtonContent = styled.button`
  width: 49%;
  padding: 0 1.5rem;
  height: 2.5rem;
  background: red;
  color: #fff;
  border-radius: 0.625rem;
  border: 0;

  font-size: 1rem;
  font-weight: 600;

  margin-top: 1.5rem;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  & + button {
    margin-left: 1rem;
    background: blue;
  }
`;

export const Status = styled.div<StatusColor>`
  cursor: pointer;

  ${({ theme, statusColor }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 0;

    margin: 0 auto;

    max-width: 72px;
    height: 12px;
    padding: 0.75rem;

    font-size: clamp(0.1rem, 0.5rem + 1vw, 0.7rem);
    font-weight: 600;
    color: ${theme.colors.ice};

    background: ${statusColor};
    box-shadow: 2px 2px 2px rgba(91, 101, 146, 0.3);
    border-radius: 10px;

    &:hover {
      opacity: 0.8;
    }
  `}
`;
