import { useState } from 'react';
import { FiMoon, FiSun, FiAlignJustify } from 'react-icons/fi';

import { useTheme } from 'hooks/theme';

import { SidebarModal } from 'components/Modal/SidebarModal';
import ConfigurationsMenu from 'components/Modal/UserMenu';
import { UserAvatar } from 'components/Avatar';
import { Customer as CustomerImage } from 'components/Customer';

import Sidebar from '../Sidebar';

import { Container, CollapsedMenu, User, Avatar, Theme } from './styles';

export default function Header() {
  const { ToggleTheme, theme } = useTheme();

  const [isNewSidebarModalOpen, setIsNewSidebarModalOpen] = useState(false);
  const [isMenuUserModalOpen, setIsMenuUserModalOpen] = useState(false);

  function handleSidebarContent() {
    setIsNewSidebarModalOpen(true);
  }

  function handleCloseNewUserModal() {
    setIsNewSidebarModalOpen(false);
  }

  return (
    <>
      <Container>
        <CollapsedMenu>
          <FiAlignJustify size="24" onClick={handleSidebarContent} />
        </CollapsedMenu>

        <User>
          <CustomerImage />

          <Theme>
            <div>
              {theme.title === 'light' ? (
                <FiMoon onClick={ToggleTheme} size="24" />
              ) : (
                <FiSun onClick={ToggleTheme} size="24" />
              )}
            </div>
          </Theme>
          <Avatar
            onClick={() =>
              isMenuUserModalOpen
                ? setIsMenuUserModalOpen(false)
                : setIsMenuUserModalOpen(true)
            }
          >
            <UserAvatar />
          </Avatar>
        </User>
      </Container>

      <ConfigurationsMenu
        isOpen={isMenuUserModalOpen}
        onRequestClose={() => setIsMenuUserModalOpen(false)}
      />

      <SidebarModal
        isOpen={isNewSidebarModalOpen}
        onRequestClose={handleCloseNewUserModal}
      >
        <aside style={{ height: '100%' }}>
          <Sidebar sidebar />
        </aside>
      </SidebarModal>
    </>
  );
}
