// eslint-disable-next-line no-shadow
export enum RouteTypeEnum {
  private = 'private',
  session = 'session',
}

export const isPrivate = (routeType: RouteTypeEnum) =>
  routeType === RouteTypeEnum.private;

export const isSession = (routeType: RouteTypeEnum) =>
  routeType === RouteTypeEnum.session;
