import { ChangeEvent, useRef, useState, useEffect } from 'react';
import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import { api } from 'services/api';

import { useTheme } from 'hooks/theme';

import { NewModal } from 'components/Modal';
import Button from 'components/Button';

import { generateAvatars } from 'utils/generateAvatars';

import {
  Container,
  AvatarInput,
  Form,
  Content,
  ContentInputRange,
} from './styles';

interface IUploadImageProps {
  isOpen: boolean;
  onRequestClose: () => void;
  fieldName: string;
  apiUrl: string;
  imageUrl?: string;
  name?: string;
}

export function UploadAvatar({
  isOpen,
  onRequestClose,
  fieldName,
  apiUrl,
  imageUrl,
  name,
}: IUploadImageProps) {
  const { theme } = useTheme();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<FormHandles>(null);
  const editor = useRef<AvatarEditor>(null);

  const allowZoomOut = false;

  const [isUploadNotEnabled, setIsUploadNotEnabled] = useState(true);
  const [avatarEditor, setAvatarEditor] = useState<AvatarEditorProps>(
    {} as AvatarEditorProps,
  );
  const [notContainAvatar, setNotContainAvatar] = useState(false);

  useEffect(() => {
    setAvatarEditor({
      image: imageUrl || generateAvatars(name),
      scale: 1.0,
      rotate: 0,
    });
    setIsUploadNotEnabled(true);
    setNotContainAvatar(!imageUrl);
  }, [isOpen]);

  const handleAvatarChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const image = e.target.files[0];

      const { size } = image;

      const maxSize = 2 * 1000 * 1000;

      if (size > maxSize) {
        toast.error('Tamanho da imagem acima do suportado: 2.0 MB');

        return;
      }

      setAvatarEditor({ ...avatarEditor, image });
      setIsUploadNotEnabled(false);
      setNotContainAvatar(false);
    }
  };

  const handleScale = (scale: number) => {
    setAvatarEditor({ ...avatarEditor, scale });
  };

  const handleRotate = (rotate: number) => {
    setAvatarEditor({ ...avatarEditor, rotate });
  };

  const handleDeleteImage = async () => {
    try {
      await api.delete(apiUrl);

      setAvatarEditor({
        image: generateAvatars(name),
        scale: 1.0,
        rotate: 0,
      });
      setIsUploadNotEnabled(false);
      setNotContainAvatar(true);
    } catch {
      toast.error('Erro ao tentar remover o avatar, tente novamente!');
    }
  };

  const handleNewImage = async () => {
    if (notContainAvatar) {
      onRequestClose();
      return;
    }

    const canvas = editor.current?.getImage();

    try {
      canvas?.toBlob(async blob => {
        const data = new FormData();
        if (blob) {
          const file = new File([blob], 'profile.png', {
            type: 'image/png',
          });

          data.append(fieldName, file);

          await api.patch(apiUrl, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          toast.success('Avatar atualizado!');
          onRequestClose();
        }
      });
    } catch {
      toast.error('Erro ao tentar atualizar o avatar, tente novamente!');
    }
  };

  return (
    <NewModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Container>
        <h1>Editar foto</h1>

        <Form onSubmit={handleNewImage} ref={formRef}>
          <input
            id="fileButton"
            type="file"
            hidden
            ref={fileInputRef}
            onChange={handleAvatarChange}
          />
          <Content>
            <div className="avatar">
              <AvatarInput
                image={avatarEditor.image}
                width={250}
                height={250}
                border={0}
                scale={avatarEditor.scale}
                rotate={avatarEditor.rotate}
                ref={editor}
              />
              {!isUploadNotEnabled && <span>Arraste para posicionar</span>}
            </div>
            <ContentInputRange>
              <div>
                <label htmlFor="zoom">Zoom</label>
                <input
                  id="zoom"
                  name="scale"
                  type="range"
                  min={allowZoomOut ? '0.1' : '1'}
                  max="2"
                  step="0.01"
                  value={avatarEditor.scale}
                  onChange={e => handleScale(parseFloat(e.target.value))}
                  disabled={isUploadNotEnabled || notContainAvatar}
                />
              </div>
              <div>
                <label htmlFor="zoom">Rotacionar</label>
                <input
                  name="rotate"
                  type="range"
                  min="-45"
                  max="45"
                  step="0.01"
                  value={avatarEditor.rotate}
                  onChange={e => handleRotate(parseFloat(e.target.value))}
                  disabled={isUploadNotEnabled || notContainAvatar}
                />
              </div>
            </ContentInputRange>
          </Content>
          <footer>
            <div>
              <div>
                <Button
                  type="button"
                  onClick={() => handleDeleteImage()}
                  color={theme.colors.redGradient}
                  disabled={notContainAvatar}
                >
                  Excluir foto
                </Button>
                <Button
                  type="button"
                  onClick={() => fileInputRef?.current?.click()}
                  color={theme.colors.blueGradient}
                >
                  Alterar foto
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  color={theme.colors.greenGradient}
                  disabled={isUploadNotEnabled}
                >
                  Confirmar alteração
                </Button>
              </div>
            </div>
          </footer>
        </Form>
      </Container>
    </NewModal>
  );
}
