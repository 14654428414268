import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface IContainerProps {
  isErrored: boolean;
}

export const Container = styled.div<IContainerProps>`
  label {
    margin-left: 0.2rem;

    ${({ theme }) => css`
      color: ${theme.title === 'dark'
        ? theme.colors.ice
        : theme.colors.darkBlue};
      font-weight: 600;
      font-size: 0.875rem;

      strong {
        margin-right: 0.1rem;
        color: ${theme.colors.lightRed};
      }
    `}
  }

  .react-select__control {
    background: ${({ theme }) => theme.colors.ice};
    border-radius: 0.625rem;
    min-height: 2.838rem;
    font-weight: 500;
    font-size: 1rem;
    border: 2px solid ${({ theme }) => theme.colors.ice};

    ${({ theme, isErrored }) =>
      isErrored &&
      css`
        border-color: ${theme.colors.lightRed};
      `}

    &:hover {
      border-color: ${props => props.theme.colors.lightGreen};
    }
  }

  .react-select__multi-value__remove {
    color: ${props => props.theme.colors.cyan};
  }

  .react-select__single-value {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  .react-select__menu {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  .react-select__control--is-disabled {
    opacity: 0.6;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;

  svg {
    margin: 0;
  }

  span {
    background: ${({ theme }) => theme.colors.lightRed};
    color: ${({ theme }) => theme.colors.ice};

    &::before {
      border-color: ${({ theme }) => theme.colors.lightRed} transparent;
    }
  }
`;
