import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  gap: 1rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  }

  @media screen and (max-width: 468px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 0.8rem;
  }
`;
