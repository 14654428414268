import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';

import { api } from 'services/api';

import { useTheme } from 'hooks/theme';

import Button from 'components/Button';
import DualListBox from 'components/DualListBox';

import getValidationErrors from 'utils/getValidationErrors';

import { IFormAction } from 'models/ITableActions';

import { Container, ButtonContainer } from './styles';

interface IModule {
  [key: string]: Object;
  id: string;
  nome: string;
  icone: string;
}

interface ICompanyModule {
  id: string;
  modulo: IModule;
}

interface IFormData {
  grupo: IModule[];
}

interface IComponentProps {
  action: IFormAction;
  onRequestClose: () => void;
}

export default function LinkModule({
  action,
  onRequestClose,
}: IComponentProps) {
  const formRef = useRef<FormHandles>(null);
  const { theme } = useTheme();

  const { colors } = theme;

  const [companyModule, setCompanyModule] = useState<IModule[]>();
  const [module, setModule] = useState<IModule[]>();
  const [loading, setLoading] = useState(false);

  const handleSubmit: SubmitHandler<IFormData> = async (data, { reset }) => {
    try {
      setLoading(true);

      if (!companyModule) {
        data.grupo.forEach(async itemModule => {
          const save = {
            modulo_id: itemModule.id,
            empresa_id: action.id,
          };

          await api.post('/company-module', save);
        });
      } else {
        await api.delete(`/company-module/exclude-relations/${action.id}`);

        if (data.grupo.length > 0) {
          data.grupo.forEach(async itemCompanyModule => {
            const save = {
              modulo_id: itemCompanyModule.id,
              empresa_id: action.id,
            };

            await api.post('/company-module', save);
          });
        }
      }

      toast.success('Módulo alterado com sucesso!');

      reset();
      onRequestClose();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        toast.error(
          'Ocorreu um erro ao tentar gravar os dados, tente novamente!',
          { delay: 400 },
        );
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    async function getModules() {
      const [moduleResponse, companyModuleResponse] = await Promise.allSettled([
        api.get<IModule[]>('/module').then(response => response.data),
        api
          .get<ICompanyModule[]>(
            `company-module/all-modules-by-company/${action.id}`,
          )
          .then(response => response.data),
      ]);

      if (
        moduleResponse.status === 'fulfilled' &&
        companyModuleResponse.status === 'fulfilled'
      ) {
        const modulesAvailable: IModule[] = [];
        const refactorCompanyModule: IModule[] = [];

        moduleResponse.value.forEach(moduleItem => {
          const validCompanyModulesAvailable = companyModuleResponse.value.find(
            companyModuleItem => moduleItem.id === companyModuleItem.modulo.id,
          );

          if (!validCompanyModulesAvailable) {
            modulesAvailable.push(moduleItem);
          } else {
            refactorCompanyModule.push(validCompanyModulesAvailable.modulo);
          }
        });

        setModule(modulesAvailable);
        setCompanyModule(refactorCompanyModule);
      } else if (moduleResponse.status === 'fulfilled') {
        setModule(moduleResponse.value);
      }
    }

    getModules();
  }, []);

  return (
    <Container>
      <h2>Empresa: {action.nome}</h2>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <DualListBox
          title="Módulos de acesso"
          blocked={module}
          released={companyModule}
          name="grupo"
        />

        <ButtonContainer>
          <div>
            <Button
              type="button"
              color={colors.redGradient}
              onClick={onRequestClose}
              disabled={loading}
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              color={colors.greenGradient}
              loading={loading}
            >
              Gravar
            </Button>
          </div>
        </ButtonContainer>
      </Form>
    </Container>
  );
}
