import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';

import { useCompany } from 'hooks/company';
import { useAuth } from 'hooks/auth';

import { UploadLogo } from 'components/ImageUpload';

import logoCustomer from 'assets/images/logo-customer.svg';

import { Container } from './styles';

export function Customer() {
  const { companyUser } = useCompany();
  const { handleUserRender } = useAuth();

  const [isEditable, setIsEditable] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  function handleOpenNewModal() {
    setIsNewModalOpen(true);
  }

  function handleCloseNewModal() {
    handleUserRender();
    setIsNewModalOpen(false);
  }

  return (
    <>
      <Container>
        <div
          onMouseEnter={() => setIsEditable(true)}
          onMouseLeave={() => setIsEditable(false)}
        >
          <img
            src={
              companyUser.empresa && companyUser.empresa.logo_url
                ? companyUser.empresa.logo_url
                : logoCustomer
            }
            alt="Logo empresa"
          />

          {isEditable && (
            <FaEdit className="icon-edit" onClick={handleOpenNewModal} />
          )}
        </div>
      </Container>

      <UploadLogo
        isOpen={isNewModalOpen}
        onRequestClose={() => handleCloseNewModal()}
        fieldName="logo"
        apiUrl={`company/image/logo/${companyUser.empresa_id}`}
        imageUrl={companyUser.empresa?.logo_url}
      />
    </>
  );
}
