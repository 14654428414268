import { useEffect, useRef, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';

import { useCompany } from 'hooks/company';

import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Select';
import DualListBox from 'components/DualListBox';

import { api } from 'services/api';

import getValidationErrors from 'utils/getValidationErrors';

import { IFormAction } from 'models/ITableActions';

import { useTheme } from 'hooks/theme';
import { Container, Content, FormGroup, ButtonContainer } from './styles';

interface IFormData {
  nome: string;
  empresa_id: string;
  module_id?: string;
  grupo_aplicacao: IApplication[];
}

interface IApplication {
  [key: string]: Object;
  id: string;
  nome: string;
}

interface IModule {
  id: string;
  nome: string;
  aplicacao: IApplication[];
}

interface ICompanyModule {
  id: string;
  modulo: IModule;
}

interface IComponentProps {
  action: IFormAction;
  onRequestClose: () => void;
}

interface ISelectProps {
  value: string;
  label: string;
}

export default function CrudAccessGroup({
  action,
  onRequestClose,
}: IComponentProps) {
  const { typeOfAction } = action;

  const formRef = useRef<FormHandles>(null);
  const { companyUser } = useCompany();
  const { theme } = useTheme();

  const { colors } = theme;

  const [loading, setLoading] = useState(false);
  const [optionsSelectModule, setOptionsSelectModule] = useState<
    ISelectProps[]
  >([]);
  const [application, setApplication] = useState<IApplication[]>([]);
  const [module, setModule] = useState<ICompanyModule[]>([]);
  const [released, setReleased] = useState([]);

  const handleFormatAccessGroupApplication = (
    grupoApplication: IApplication[],
    grupoAcessoId: string,
  ) =>
    grupoApplication.map(groupApplication => ({
      grupo_acesso_id: grupoAcessoId,
      aplicacao_id: groupApplication.id,
    }));

  const handleSubmit: SubmitHandler<IFormData> = async (data, { reset }) => {
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      const { nome, grupo_aplicacao } = data;

      const formData = {
        nome,
        empresa_id: companyUser.empresa_id,
      };

      if (action.id) {
        await api.put(`access-group/${action?.id}`, formData);

        if (released.length > 0)
          await api.delete(`/access-group-application/${action?.id}`);

        const groupApplicationFormated = handleFormatAccessGroupApplication(
          grupo_aplicacao,
          action?.id,
        );

        await api.post('access-group-application', groupApplicationFormated);
      } else {
        const responseData = await api.post('access-group', formData);

        const groupApplicationFormated = handleFormatAccessGroupApplication(
          grupo_aplicacao,
          responseData.data.id,
        );

        await api.post('access-group-application', groupApplicationFormated);
      }

      toast.success('Grupo de acesso salvo com sucesso!');

      reset();
      onRequestClose();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        toast.error('Erro ao gravar os dados, tente novamente!', {
          delay: 400,
        });
      }
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    await api.delete(`access-group/${action?.id}`);

    toast.success('Grupo de acesso excluído com sucesso!');
    onRequestClose();
  };

  const handleModuleApplication = (e: any) => {
    const verifyModule = module.find(item => item.modulo.id === e.value);

    const applicationReleased =
      formRef.current?.getFieldValue('grupo_aplicacao');

    const saveApplication: IApplication[] = [];

    applicationReleased.length > 0
      ? verifyModule?.modulo.aplicacao.forEach((item: IApplication) => {
          const compare = applicationReleased.findIndex(
            (itemApplication: IApplication) => itemApplication.id === item.id,
          );

          compare === -1 && saveApplication.push(item);
        })
      : verifyModule && setApplication(verifyModule.modulo.aplicacao);

    applicationReleased.length > 0 && setApplication(saveApplication);
  };

  useEffect(() => {
    async function getIndexAccessGroup() {
      try {
        const response = await Promise.all([
          api.get(`/access-group-application/groups/${action?.id}`),
          api.get(`/access-group/${action?.id}`),
        ]);

        const [accessGroupApplication, accessGroup] = response;

        accessGroupApplication && setReleased(accessGroupApplication.data);
        accessGroup &&
          formRef.current?.setFieldValue('nome', accessGroup.data.nome);
      } catch {
        toast.error('Erro ao carregar os dados .');
      }
    }
    action?.id && getIndexAccessGroup();
  }, []);

  useEffect(() => {
    async function getModules() {
      await api
        .get<ICompanyModule[]>(
          `/company-module/all-modules-by-company/${companyUser.empresa_id}`,
        )
        .then(response => {
          const moduleResponse = response.data.map(item => ({
            value: item.modulo.id,
            label: item.modulo.nome,
          }));

          setModule(response.data);
          setOptionsSelectModule(moduleResponse);
        })
        .catch(() => {
          toast.warning('Sem módulos vinculados.');
        });
    }

    getModules();
  }, []);

  const titleModal = useMemo(
    () =>
      !action?.id
        ? 'Cadastro de Grupo de Acesso'
        : action.typeOfAction === 'update'
        ? 'Alteração de Grupo de Acesso'
        : 'Exclusão de Grupo de Acesso',
    [action],
  );

  return (
    <Container>
      <h2>{titleModal}</h2>

      <Content ref={formRef} onSubmit={handleSubmit}>
        <FormGroup>
          <Input
            name="nome"
            label="Nome"
            placeholder="Nome"
            requiredField
            disabled={typeOfAction === 'delete' && true}
          />
          <Select
            name="module_id"
            label="Modulo"
            options={optionsSelectModule}
            onChange={handleModuleApplication}
            requiredField
            isDisabled={typeOfAction === 'delete' && true}
          />
        </FormGroup>
        <section className="duallist">
          <DualListBox
            title="Aplicações"
            blocked={application}
            released={released}
            name="grupo_aplicacao"
          />
        </section>

        <ButtonContainer>
          {typeOfAction === 'delete' && (
            <span>Deseja realmente excluir este Grupo de Acesso?</span>
          )}
          <div>
            <Button
              type="button"
              color={colors.redGradient}
              onClick={onRequestClose}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              type={typeOfAction === 'delete' ? 'button' : 'submit'}
              color={colors.greenGradient}
              loading={loading}
              onClick={typeOfAction === 'delete' ? handleDelete : undefined}
            >
              {typeOfAction === 'delete' ? 'Excluir' : 'Gravar'}
            </Button>
          </div>
        </ButtonContainer>
      </Content>
    </Container>
  );
}
