import { ReactNode, Suspense } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { AxiosError } from 'axios';

import { api, webBff } from 'services/api';

import { IRouteProperties } from 'models/IRoutes';
import { isPrivate, RouteTypeEnum, isSession } from 'routes/routeTypes';
import { useAuth } from 'hooks/auth';

import { Loading } from 'components/Loading';
import { Can } from 'components/Can';

interface AuthProperties extends ReactDOMRouteProps {
  appRoute: IRouteProperties;
  type: RouteTypeEnum;
  template: React.ComponentType<ReactNode>;
}

const Auth = ({
  appRoute,
  type,
  template: Template,
  ...rest
}: AuthProperties) => {
  const { signOut } = useAuth();

  const token = localStorage.getItem('@Insight:token');

  if (isSession(type) && token) {
    return (
      <Redirect
        to={{
          pathname: isPrivate(type) ? '/' : '/home',
        }}
      />
    );
  }

  if (isPrivate(type) && token) {
    api.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          signOut();
        }
        return Promise.reject(error);
      },
    );
    webBff.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          signOut();
        }
        return Promise.reject(error);
      },
    );
  }

  if (isPrivate(type) && !token) {
    return <Redirect to="/" />;
  }

  const { component, rolePermissions } = appRoute;

  const Content = component;
  const Layout = Template;

  return (
    <ReactDOMRoute
      {...rest}
      render={() => (
        <Layout>
          {rolePermissions ? (
            <Can roles={rolePermissions} isAnAccessViaRouter>
              <Suspense fallback={<Loading />}>
                <Content />
              </Suspense>
            </Can>
          ) : (
            <Suspense fallback={<Loading />}>
              <Content />
            </Suspense>
          )}
        </Layout>
      )}
    />
  );
};

export default Auth;
