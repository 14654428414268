import { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import * as Yup from 'yup';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';

import { api } from 'services/api';

import { useTheme } from 'hooks/theme';

import Input from 'components/Input';
import Button from 'components/Button';

import getValidationErrors from 'utils/getValidationErrors';

import { IFormAction } from 'models/ITableActions';

import { Container, Content, ButtonContainer } from './styles';

interface IContacts {
  nome: string;
  ddd: number;
  telefone: string;
}

interface IFormData {
  nome_fantasia: string;
  razao_social: string;
  cnpj: string;
  cep: string;
  bairro: string;
  cidade: string;
  rua: string;
  numero: string;
  complemento?: string;
  ddd: number;
  telefone: string;
  nome: string;
  quantidade_licenca: number;
}

interface ISubmitCompany {
  razao_social: string;
  nome_fantasia: string;
  cnpj: string;
  status?: boolean;
  quantidade_licenca?: number;
  endereco_id: string;
  contatos?: IContacts[];
}

interface ComponentProps {
  action: IFormAction;
  onRequestClose: () => void;
}

export default function CrudCompany({
  action,
  onRequestClose,
}: ComponentProps) {
  const { typeOfAction } = action;

  const formRef = useRef<FormHandles>(null);
  const { theme } = useTheme();

  const { colors } = theme;

  const [loading, setLoading] = useState(false);
  const [enderecoId, setEnderecoId] = useState('');
  const [contatoId, setContatoId] = useState('');

  const handleSubmit: SubmitHandler<IFormData> = async (data, { reset }) => {
    try {
      const schema = Yup.object().shape({
        razao_social: Yup.string().required('Razão social obrigatória.'),
        nome_fantasia: Yup.string().required('Nome fantasia obrigatório.'),
        cnpj: Yup.string().required('CNPJ obrigatório.'),
        quantidade_licenca: Yup.number()
          .min(0, 'Mínimo 0')
          .integer()
          .required('Licenças obrigatórias.'),
        cep: Yup.string().required('CEP obrigatório.'),
        bairro: Yup.string().required('Bairro obrigatório.'),
        cidade: Yup.string().required('Cidade obrigatória.'),
        numero: Yup.string().required('Número obrigatório.'),
        rua: Yup.string().required('Rua obrigatória.'),
        complemento: Yup.string(),
        nome: Yup.string().required('Nome do contato obrigatório.'),
        ddd: Yup.number().integer().required('DDD obrigatório.'),
        telefone: Yup.string().required('Telefone obrigatório.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      const {
        bairro,
        cep,
        numero,
        rua,
        cidade,
        complemento,
        nome,
        ddd,
        telefone,
      } = data;

      const city = cidade.split('-')[0];
      const uf = cidade.split('-')[1];

      let cityId = await api
        .get(`city/${city}`)
        .then(response => response.data.id);

      if (!cityId) {
        cityId = await api
          .post(`city`, { nome: city, estado_uf: uf })
          .then(response => response.data.id);
      }

      const address = {
        bairro,
        cep,
        numero,
        rua,
        complemento,
        cidade_id: cityId,
      };

      const contatos = [
        {
          nome,
          ddd,
          telefone,
        },
      ];

      let submitData: ISubmitCompany;
      let addressId;

      if (action.id) {
        addressId = await api
          .put(`address/${enderecoId}`, address)
          .then(response => response.data.id);

        await api.put(`company/fone/${contatoId}`, contatos[0]);

        submitData = {
          razao_social: data.razao_social,
          nome_fantasia: data.nome_fantasia,
          cnpj: data.cnpj,
          endereco_id: addressId,
        };

        await api.put(`company/${action?.id}`, submitData);
      } else {
        addressId = await api
          .post(`address/`, address)
          .then(response => response.data.id);

        submitData = {
          razao_social: data.razao_social,
          nome_fantasia: data.nome_fantasia,
          cnpj: data.cnpj,
          quantidade_licenca: data.quantidade_licenca,
          endereco_id: addressId,
          contatos,
        };

        await api.post('company', submitData);
      }

      toast.success('Empresa salva com sucesso!');

      reset();
      onRequestClose();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        toast.error(
          'Ocorreu um erro ao tentar gravar os dados, tente novamente!',
          { delay: 400 },
        );
      }

      setLoading(false);
    }
  };

  const handleDelete = async () => {
    await api.delete(`company/${action?.id}`);
    onRequestClose();
    toast.success('Empresa excluída com sucesso!');
  };

  useEffect(() => {
    async function getIndexCompany() {
      await api
        .get(`/company/${action?.id}`)
        .then(response => {
          const { data } = response;

          formRef.current?.setData({
            razao_social: data.razao_social,
            nome_fantasia: data.nome_fantasia,
            cnpj: data.cnpj,
            status: data.status,
            quantidade_licenca: data.quantidade_licenca,
            cep: data.endereco.cep,
            bairro: data.endereco.bairro,
            cidade: data.endereco.cidade.nome,
            numero: data.endereco.numero,
            rua: data.endereco.rua,
            complemento: data.endereco.complemento,
            nome: data.contatos[0].nome,
            ddd: data.contatos[0].ddd,
            telefone: data.contatos[0].telefone,
          });

          setContatoId(data.contatos[0].id);
          setEnderecoId(data.endereco.id);
        })
        .catch(() => {
          toast.error('Erro ao carregar os dados.');
        });
    }
    action?.id && getIndexCompany();
  }, [action]);

  const handleCep = useCallback(async () => {
    try {
      const response = await api.get(
        `cep-promise/${formRef.current?.getFieldValue('cep')}`,
      );

      const { cep, neighborhood, city, street, state } = response.data;
      const formData = formRef.current?.getData();

      formRef.current?.setData({
        ...formData,
        bairro: neighborhood,
        cep,
        cidade: `${city}-${state}`,
        rua: street,
      });
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar buscar o cep, tente novamente!', {
        delay: 400,
      });
    }
  }, [formRef.current?.getFieldValue('cep')]);

  const titleModal = useMemo(
    () =>
      !action?.id
        ? 'Cadastro de Empresa'
        : action.typeOfAction === 'update'
        ? 'Alteração de Empresa'
        : 'Exclusão de Empresa',
    [action],
  );

  return (
    <Container>
      <h2>{titleModal}</h2>
      <Content ref={formRef} onSubmit={handleSubmit}>
        <section>
          <Input
            name="razao_social"
            label="Razão Social"
            placeholder="Razão Social"
            requiredField
            disabled={action?.typeOfAction === 'delete' && true}
          />
          <Input
            name="nome_fantasia"
            label="Nome Fantasia"
            placeholder="Nome Fantasia"
            requiredField
            disabled={action?.typeOfAction === 'delete' && true}
          />
          <Input
            name="cnpj"
            label="CNPJ"
            placeholder="CNPJ"
            requiredField
            disabled={action?.typeOfAction === 'delete' && true}
          />
          <Input
            name="quantidade_licenca"
            label="Licenças"
            placeholder="Licenças"
            requiredField
            type="number"
            defaultValue="0"
            disabled={
              action?.typeOfAction === 'delete' ||
              (action?.typeOfAction === 'update' && true)
            }
          />
        </section>
        <hr />
        <section>
          <Input
            name="cep"
            label="CEP"
            placeholder="CEP"
            onBlurCapture={() => handleCep()}
            requiredField
            disabled={typeOfAction === 'delete' && true}
          />
          <Input
            name="bairro"
            label="Bairro"
            placeholder="Bairro"
            requiredField
            disabled={typeOfAction === 'delete' && true}
          />
          <Input
            name="cidade"
            label="Cidade"
            placeholder="Cidade"
            requiredField
            disabled
          />
          <Input
            name="numero"
            label="N"
            placeholder="N."
            requiredField
            disabled={typeOfAction === 'delete' && true}
          />
          <Input
            name="rua"
            label="Rua"
            placeholder="Rua"
            requiredField
            disabled={typeOfAction === 'delete' && true}
          />
          <Input
            name="complemento"
            label="Complemento"
            placeholder="Complemento"
            disabled={typeOfAction === 'delete' && true}
          />
        </section>
        <hr />
        <section>
          <Input
            name="nome"
            label="Nome do Contato"
            placeholder="Nome do Contato"
            requiredField
            disabled={typeOfAction === 'delete'}
          />
          <Input
            name="ddd"
            label="DDD"
            placeholder="DDD"
            requiredField
            type="number"
            defaultValue="0"
            disabled={typeOfAction === 'delete'}
          />
          <Input
            name="telefone"
            label="Telefone/Celular"
            placeholder="Telefone/Celular"
            requiredField
            disabled={typeOfAction === 'delete'}
          />
        </section>

        <ButtonContainer>
          {typeOfAction === 'delete' && (
            <span>Deseja realmente excluir este módulo?</span>
          )}
          <div>
            <Button
              type="button"
              color={colors.redGradient}
              onClick={onRequestClose}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              color={colors.greenGradient}
              loading={loading}
              type={typeOfAction === 'delete' ? 'button' : 'submit'}
              onClick={typeOfAction === 'delete' ? handleDelete : undefined}
            >
              {typeOfAction === 'delete' ? 'Excluir' : 'Gravar'}
            </Button>
          </div>
        </ButtonContainer>
      </Content>
    </Container>
  );
}
