import styled from 'styled-components';

export const Container = styled.div`
  .MuiTableCell-footer {
    border: none;
  }

  .MuiToolbar-gutters {
    padding: 0rem;
  }

  .MuiPaper-root {
    padding-top: 0px !important;
  }

  .MuiTypography-caption {
    color: ${props => props.theme.colors.text};
  }

  .MuiChip-label {
    color: ${props => props.theme.colors.text};
  }

  .MuiTableSortLabel-active div {
    color: ${props => props.theme.colors.darkBlue};
  }
`;

export const Title = styled.h6`
  color: #5b6592;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;

  margin: 1rem 0;
`;
