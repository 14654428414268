import { useTheme } from 'hooks/theme';

import insightColoredImg from 'assets/images/icone-insight-colored.svg';
import insightDarkImg from 'assets/images/icone-insight-dark.svg';

import { Container } from './styles';

export function Loading() {
  const { theme } = useTheme();

  return (
    <Container>
      <img
        src={theme.title === 'dark' ? insightDarkImg : insightColoredImg}
        alt="logo-loading"
      />
    </Container>
  );
}
