import { ReactNode } from 'react';

import { Container } from './styles';

interface IPropsGrid {
  children: ReactNode;
  numberOfColumns?: 3 | 2;
}

export function CardGrid({ children, numberOfColumns = 2 }: IPropsGrid) {
  return <Container numberOfColumns={numberOfColumns}>{children}</Container>;
}
