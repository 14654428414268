import { useEffect, useRef, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';

import { api } from 'services/api';

import { useTheme } from 'hooks/theme';

import Input from 'components/Input';
import Button from 'components/Button';

import { useMenu } from 'hooks/menu';
import { useCompany } from 'hooks/company';

import getValidationErrors from 'utils/getValidationErrors';

import { IFormAction } from 'models/ITableActions';

import { Container, Content, ButtonContainer } from './styles';

interface IFormData {
  nome: string;
  icone: string;
}

interface ComponentProps {
  action: IFormAction;
  onRequestClose: () => void;
}

export default function CrudModule({ action, onRequestClose }: ComponentProps) {
  const { typeOfAction } = action;

  const formRef = useRef<FormHandles>(null);

  const { handleUpdatingDataRealTime } = useMenu();
  const { companyUser } = useCompany();
  const { theme } = useTheme();

  const { colors } = theme;

  const [loading, setLoading] = useState(false);

  const handleSubmit: SubmitHandler<IFormData> = async (data, { reset }) => {
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório'),
        icone: Yup.string().required('Icone obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      if (action.id) {
        await api.put(`module/${action?.id}`, data);
      } else {
        await api.post('module', data);
      }

      await handleUpdatingDataRealTime(companyUser.empresa_id);

      reset();
      onRequestClose();
      toast.success('Módulo salvo com sucesso!');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        toast.error(
          'Ocorreu um erro ao tentar gravar os dados, tente novamente!',
          { delay: 400 },
        );
      }

      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await api.delete(`module/${action?.id}`);

      toast.success('Módulo excluído com sucesso!');
      onRequestClose();
    } catch {
      toast.error('Ocorreu um problema ao tentar excluir o módulo!');
    }
  };

  useEffect(() => {
    async function getIndexModule() {
      await api
        .get(`/module/${action?.id}`)
        .then(response => {
          const { data } = response;
          formRef.current?.setData({
            nome: data.nome,
            icone: data.icone,
          });
        })
        .catch(() => {
          toast.error('Ocorreu um problema ao tentar carregar os dados.');
        });
    }
    action?.id && getIndexModule();
  }, [action]);

  const titleModal = useMemo(
    () =>
      !action?.id
        ? 'Cadastro de Módulo'
        : typeOfAction === 'update'
        ? 'Alteração de Módulo'
        : 'Exclusão de Módulo',
    [action],
  );

  return (
    <Container>
      <h2>{titleModal}</h2>
      <Content ref={formRef} onSubmit={handleSubmit}>
        <section>
          <Input
            name="nome"
            label="Nome"
            placeholder="Nome"
            requiredField
            disabled={typeOfAction === 'delete' && true}
          />
          <Input
            name="icone"
            label="Icone"
            placeholder="Icone"
            requiredField
            disabled={typeOfAction === 'delete' && true}
          />
        </section>
        <ButtonContainer>
          {typeOfAction === 'delete' && (
            <span>Deseja realmente excluir este módulo?</span>
          )}
          <div>
            <Button
              type="button"
              color={colors.redGradient}
              onClick={onRequestClose}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              type={typeOfAction === 'delete' ? 'button' : 'submit'}
              color={colors.greenGradient}
              loading={loading}
              onClick={typeOfAction === 'delete' ? handleDelete : undefined}
            >
              {typeOfAction === 'delete' ? 'Excluir' : 'Gravar'}
            </Button>
          </div>
        </ButtonContainer>
      </Content>
    </Container>
  );
}
