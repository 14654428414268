import styled, { css } from 'styled-components';

export const Container = styled.footer`
  z-index: 1;

  grid-area: footer;

  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 41px;

  ${({ theme }) => css`
    background: ${theme.title === 'dark'
      ? theme.colors.backgroundBox
      : theme.colors.ice};
    box-shadow: ${theme.boxShadow};
  `}

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    padding: 0px 16px;
  }

  span {
    font-size: clamp(0.1rem, 0.75rem + 1vw, 1rem);

    ${({ theme }) => css`
      color: ${theme.title === 'dark'
        ? theme.colors.ice
        : theme.colors.darkBlue};
    `}

    strong {
      font-weight: 600;
    }

    @media (max-width: 480px) {
      margin: 0 auto;
    }
  }
`;
