import styled, { css, keyframes } from 'styled-components';
import Modal from 'react-modal';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateX(55px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled(Modal)`
  position: fixed;

  width: 15rem;
  top: 4rem;
  right: 2.5rem;

  display: block;

  border-radius: 5px;

  animation: ${appearFromTop} 0.3s ease-in-out;

  @media (max-width: 768px) {
    right: 1rem;
  }

  @media (max-width: 480px) {
    right: 0;
    width: 100%;
  }

  ${({ theme }) => css`
    background: ${theme.title === 'dark'
      ? theme.colors.backgroundIndex
      : theme.colors.ice};
    box-shadow: ${theme.boxShadow};
  `}

  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;

  a,
  button {
    ${({ theme }) => css`
      color: ${theme.title === 'dark'
        ? theme.colors.ice
        : theme.colors.darkBlue};
    `}
    display: flex;
    -moz-box-pack: end;
    justify-content: flex-end;
    -moz-box-align: center;
    align-items: center;

    font-size: 16px;
    padding: 12px 16px;

    transition: background 0.2s ease 0s;

    width: 100%;
    flex-direction: row-reverse;

    background: transparent;
    border: none;

    span {
      margin-left: 16px;
    }

    svg {
      display: flex;
      align-items: center;
      font-size: 1rem;
    }

    &:hover {
      ${({ theme }) => css`
        background: ${theme.title === 'dark'
          ? theme.colors.grayGradient
          : theme.colors.darkBlueGradient};
        color: ${theme.title === 'light' && theme.colors.ice};
      `}
      filter: brightness(1);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 0.8rem;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent
      ${({ theme }) => css`
        ${theme.title === 'dark'
          ? theme.colors.backgroundIndex
          : theme.colors.ice};
      `};

    @media (max-width: 480px) {
      right: 1.8rem;
    }
  }

  .react-modal-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: 0;
    background: transparent;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.5);
    }

    svg {
      font-size: 1rem;
      color: ${props =>
        props.theme.title === 'dark'
          ? props.theme.colors.ice
          : props.theme.colors.darkBlue};
    }

    @media screen and (max-width: 468px) {
      top: 0.5rem;
      right: 0.5rem;
    }
  }
`;
