export default {
  title: 'light',
  colors: {
    background: '#F2F3F5',
    backgroundOverlay: 'rgba(242, 243, 245, 0.8)',
    backgroundBox: '#FFFFFF',
    backgroundIndex: '#F7F8FF',

    text: '#38446E',

    darkBlue: '#38446E',
    darkGray: '#5B6592',
    darkGreen: '#178581',

    lightGray: '#7F89B8',
    lightGreen: '#00C6C0',
    lightRed: '#ED5D4A',

    cyan: '#638CF6',
    yellow: '#F3C13B',
    ice: '#F7F8FF',
    black: '#0d1117',
    orange: '#F2994A',

    darkBlueGradient:
      'linear-gradient(301.65deg, #38446E 18.81%, #585384 100%)',
    blueGradient: 'linear-gradient(342.83deg, #638CF6 37.99%, #00BBFF 100%)',
    darkGreenGradient:
      'linear-gradient(301.65deg, #178581 18.82%, #2F9F87 100.01%)',
    greenGradient: 'linear-gradient(301.84deg, #00C6C0 19.23%, #3BD4B4 99.87%)',
    redGradient: 'linear-gradient(295.56deg, #ED5D4A 16.3%, #C83C2F 100%)',
    yellowGradient:
      'linear-gradient(301.65deg, #F3C13B 19.01%, #FFBD60 100.2%)',
    grayGradient: 'linear-gradient(301.65deg, #444655 18.81%, #656777 100%)',
  },
  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.3)',
};
