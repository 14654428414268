import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

type IconColor = {
  iconColor: string;
};

type TitleColor = {
  titleColor: string;
};

export const Container = styled(Link)`
  ${({ theme }) => css`
    display: flex;

    flex: 0 1 calc(25% - 0.5em);
    height: 123px;
    padding: 1rem;

    color: ${theme.title === 'dark' && theme.colors.ice};

    background: ${theme.colors.backgroundBox};
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    cursor: pointer;

    transition: box-shadow, filter 0.2s ease 0s;

    :hover {
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
      filter: brightness(1.1);
    }
  `}

  @media screen and (max-width: 1280px) {
    flex: 0 1 calc(50% - 0.5em);
    padding: 0.8rem 0.8rem;
  }

  @media screen and (max-width: 768px) {
    flex: 0 1 calc(100% - 0.5em);
    padding: 1rem;
  }

  @media screen and (max-width: 468px) {
    flex: 1 0 calc(50% - 0.5em);
  }
`;

export const IconContainer = styled.div<IconColor>`
  width: 5.7rem;

  ${({ iconColor }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${iconColor};
    border-radius: 10px;

    svg {
      font-size: 4rem;
      color: white;
    }
  `}

  @media screen and (max-width: 468px) {
    width: 7.7rem;
  }
`;

export const Content = styled.section<TitleColor>`
  ${({ titleColor }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    flex: 1;

    strong {
      font-size: 1.25rem;
      font-weight: 600;

      color: ${titleColor};
    }

    p {
      font-size: 0.75rem;
    }

    padding: 0.5rem;
  `}
`;
