import styled, { keyframes } from 'styled-components';

interface ISidebarIsExpanded {
  sidebarExpanded: boolean;
}

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateX(-80px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div<ISidebarIsExpanded>`
  width: 100%;
  min-height: 100vh;

  display: grid;

  grid-template-columns: ${({ sidebarExpanded }) =>
    sidebarExpanded ? '255px calc(100% - 255px)' : '48px calc(100% - 48px)'};

  transition: all 1s;

  grid-template-rows: 3rem auto 3rem;

  header,
  main,
  footer {
    animation: ${appearFromTop} 0.3s ease-in-out;
  }

  grid-template-areas:
    'aside header'
    'aside main'
    'aside footer';

  @media screen and (max-width: 768px) {
    grid-template-columns: 100vw;

    grid-template-areas:
      'header'
      'main'
      'footer';
  }
`;

export const Main = styled.main`
  grid-area: main;
  padding: 32px 41px;

  @media screen and (max-width: 768px) {
    padding: 16px 16px;
  }
`;

export const Aside = styled.aside`
  z-index: 2;
  grid-area: aside;
  height: 100%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
