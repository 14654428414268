import SignIn from 'pages/Public/SignIn';
import ForgotPassword from 'pages/Public/ForgotPassword';
import ResetPassword from 'pages/Public/ResetPassword';
import UserActivation from 'pages/Public/UserActivation';

import { IRouteProperties } from 'models/IRoutes';

export const sessionsRoutes: IRouteProperties[] = [
  {
    component: SignIn,
    exact: true,
    path: '/',
  },
  {
    component: ForgotPassword,
    exact: true,
    path: '/forgot-password',
  },
  {
    component: ResetPassword,
    exact: true,
    path: '/reset-password',
  },
  {
    component: UserActivation,
    exact: true,
    path: '/user-activation',
  },
];
