import styled, { css } from 'styled-components';

type StatusColor = {
  statusColor: string;
};

export const Section = styled.section`
  margin-top: 1rem;
`;

export const ContentRole = styled.div`
  display: flex;

  gap: 0.8rem;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${props => props.theme.colors.cyan};
    color: ${props => props.theme.colors.ice};

    padding: 0.75rem;
    height: 12px;
    box-shadow: 2px 2px 2px rgba(91, 101, 146, 0.3);
    border-radius: 10px;
  }
`;

export const Status = styled.button<StatusColor>`
  cursor: pointer;

  ${({ theme, statusColor }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 0;

    margin: 0 auto;

    max-width: 72px;
    height: 12px;
    padding: 0.75rem;

    font-size: clamp(0.1rem, 0.5rem + 1vw, 0.7rem);
    font-weight: 600;
    color: ${theme.colors.ice};

    background: ${statusColor};
    box-shadow: 2px 2px 2px rgba(91, 101, 146, 0.3);
    border-radius: 10px;

    &:hover {
      opacity: 0.8;
    }
  `}
`;
