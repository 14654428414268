import { ReactNode } from 'react';

import { AuthProvider } from './auth';
import { CompanyProvider } from './company';
import { ThemesProvider } from './theme';
import { MenuProvider } from './menu';
import { FilterProvider } from './filter';

interface IPropsChildren {
  children: ReactNode;
}

const AppProvider = ({ children }: IPropsChildren) => (
  <ThemesProvider>
    <AuthProvider>
      <CompanyProvider>
        <FilterProvider>
          <MenuProvider>{children}</MenuProvider>
        </FilterProvider>
      </CompanyProvider>
    </AuthProvider>
  </ThemesProvider>
);
export default AppProvider;
