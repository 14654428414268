import styled, { css } from 'styled-components';
import { ICardStyleColor } from 'models/ICard';

export const Container = styled.div<ICardStyleColor>`
  height: 100%;
  max-height: 100%;

  ${({ theme }) => css`
    background: ${theme.colors.backgroundBox};
  `}

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;

  padding: 1rem;
`;

export const Header = styled.header<ICardStyleColor>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 0.7rem;

  ${({ theme, color }) => css`
    border-bottom: 1px solid ${theme.colors.text};

    p {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1rem;
      color: ${theme.colors.text};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 1.2rem;

      background: ${color || theme.colors.cyan};
      border-radius: 3px;

      span {
        font-size: 0.8rem;
        font-weight: 500;
        color: ${theme.colors.ice};
      }
    }
  `}
`;

export const Content = styled.div<ICardStyleColor>`
  display: flex;
  flex-direction: column;

  ${({ theme, color }) => css`
    strong {
      font-family: 'Rubik';
      font-size: 2.25rem;
      line-height: 4rem;
      color: ${color || theme.colors.cyan};
    }

    span {
      line-height: 0.5rem;
      font-size: 0.75rem;

      color: ${color || theme.colors.ice};
    }
  `}
`;

export const Footer = styled.div<ICardStyleColor>`
  display: flex;
  flex-direction: line;
  justify-content: space-between;
`;

export const Description = styled.span<ICardStyleColor>`
  ${({ theme, color }) => css`
    font-size: 0.75rem;
    font-weight: 100;
    display: flex;
    flex: 1;

    gap: 8px;
    color: ${theme.colors.text};

    span {
      display: flex;
      font-style: normal;
      font-weight: 600;
      color: ${theme.colors.text};

      i {
        font-style: normal;
        font-weight: 500;
        color: ${color || theme.colors.cyan};
      }
    }
  `}
`;

export const Info = styled.span<ICardStyleColor>`
  ${({ theme, color }) => css`
    display: flex;
    flex-direction: line;
    justify-content: space-between;
    color: ${color || theme.colors.cyan};

    i {
      font-size: 0.75rem;
      font-weight: 600;
      font-style: normal;

      margin-left: 0.5rem;
    }
  `}
`;
