import styled, { css, keyframes } from 'styled-components';

interface IFavoriteProps {
  isFavorite: boolean;
}

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow: hidden;

  ${({ theme }) => css`
    background: ${theme.title === 'dark'
      ? theme.colors.backgroundBox
      : theme.colors.blueGradient};
  `}
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 32px;

  animation: ${appearFromLeft} 1s;

  .logo-system {
    position: absolute;

    width: 11.625rem;
    height: 4.125rem;

    top: 32px;
    left: 32px;
  }

  h1 {
    font-size: 2rem;
    margin: 0px;
    padding: 0px 0px 24px;
    text-align: center;

    @media screen and (max-width: 1280px) {
      margin-top: 6rem;
    }

    @media screen and (max-width: 768px) {
      margin-top: 8rem;
    }

    ${({ theme }) => css`
      color: ${theme.colors.ice};
    `}
  }
`;

export const Content = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  gap: 56px;
  text-align: center;

  button {
    background: transparent;
    border: none;

    h3 {
      cursor: pointer;

      ${({ theme }) => css`
        color: ${theme.colors.ice};
      `}

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const AvatarConfig = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  margin-top: 1rem;

  .edit-icon {
    cursor: pointer;

    ${({ theme }) => css`
      color: ${theme.colors.ice};
      filter: brightness(1.3);
    `}

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Avatar = styled.div`
  width: 8rem;
  height: 8rem;

  display: block;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  margin: auto;
  transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  &:hover {
    ${({ theme }) => css`
      border: 4px solid ${theme.colors.ice};
    `}
  }
`;

export const Favorite = styled.div<IFavoriteProps>`
  svg {
    cursor: pointer;

    ${({ theme, isFavorite }) => css`
      color: ${isFavorite ? theme.colors.yellow : theme.colors.ice};
      filter: brightness(1.3);
    `}

    &:hover {
      opacity: 0.8;
    }
  }
`;
