import {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { v4 as uuid } from 'uuid';

import { IModulo } from 'models/ISidebarItem';

import { useCompany } from './company';

import { api } from '../services/api';

interface IMenuProviderProps {
  children: ReactNode;
}

interface IMenuContextData {
  menu: IModulo[];
  handleUpdatingDataRealTime(empresaId: string): Promise<void>;
  removeMenu(): void;
}

const MenuContext = createContext<IMenuContextData>({} as IMenuContextData);

const MenuProvider = ({ children }: IMenuProviderProps) => {
  const { companyUser } = useCompany();

  const [menuData, setMenuData] = useState<IModulo[]>([]);

  const defaultMenu: IModulo[] = useMemo(
    () => [
      {
        id: uuid(),
        icone: 'FaHome',
        nome: 'Home',
        aplicacao: [
          {
            id: uuid(),
            caminho: '/home',
            nome: 'Página Inicial',
            icone: 'FaHome',
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    !companyUser && removeMenu();
  }, [companyUser]);

  const handleUpdatingDataRealTime = useCallback(async (empresaId: string) => {
    try {
      const response = await api.get<IModulo[]>(
        `access-group-application/list-access-by-user/${empresaId}`,
      );

      setMenuData(defaultMenu.concat(response.data));
    } catch {
      setMenuData(defaultMenu);
    }
  }, []);

  const removeMenu = useCallback(() => {
    setMenuData({} as IModulo[]);
  }, []);

  return (
    <MenuContext.Provider
      value={{
        menu: menuData,
        removeMenu,
        handleUpdatingDataRealTime,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

function useMenu(): IMenuContextData {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu must be used within an MenuProvider');
  }

  return context;
}

export { MenuProvider, useMenu };
