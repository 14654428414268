import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { FaStar, FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { useAuth } from 'hooks/auth';
import { useCompany } from 'hooks/company';
import { UploadAvatar } from 'components/ImageUpload';

import { api } from 'services/api';

import logoInsight from 'assets/images/logo.svg';

import { generateAvatars } from 'utils/generateAvatars';

import {
  Container,
  AnimationContainer,
  Content,
  AvatarConfig,
  Avatar,
  Favorite,
} from './styles';

interface ICompany {
  id: string;
  nome_fantasia: string;
  status: boolean;
  avatar_url: string;
  logo_url: string;
}

interface ICompanyUser {
  id: string;
  favorito: boolean;
  empresa: ICompany;
}

export default function CompanySelection() {
  const { user, handleUserRender } = useAuth();
  const { addCompanySelected } = useCompany();
  const history = useHistory();

  const [userCompanies, setUserCompanies] = useState<ICompanyUser[]>();
  const [favoriteCompany, setFavoriteCompany] = useState('');
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [companyEdit, setCompanyEdit] = useState<ICompany>();

  function handleOpenNewModal() {
    setIsNewModalOpen(true);
  }

  function handleCloseNewModal() {
    setIsNewModalOpen(false);
    handleUserRender();
  }

  function handleSelectCompany(company: ICompanyUser) {
    const companySelected = {
      id: company.id,
      favorito: company.favorito,
      empresa_id: company.empresa.id,
      empresa: {
        avatar_url: company.empresa.avatar_url,
        logo_url: company.empresa.logo_url,
      },
    };

    addCompanySelected({ companySelected });

    history.push('/');
  }

  async function handleUpdateFavoriteCompany(newFavoriteCompany: string) {
    try {
      const oldFavoriteCompany = favoriteCompany;

      if (oldFavoriteCompany !== newFavoriteCompany) {
        const response = await api.put(
          `company-user/new-favorite-company/${oldFavoriteCompany}/${newFavoriteCompany}`,
        );
        setFavoriteCompany(response.data.id);
      }
    } catch (err) {
      toast.error(
        'Ocorreu um erro ao tentar trocar a empresa favorita, tente novamente!',
        { delay: 300 },
      );
    }
  }

  function handleEditCompany(companyData: ICompany) {
    setCompanyEdit(companyData);
    handleOpenNewModal();
  }

  useEffect(() => {
    async function searchCompany() {
      try {
        const response = await api.get<ICompanyUser[]>(
          `company-user/list-all-user-companies/${user?.id}`,
        );

        const companyFavorite = response.data.find(
          company => company.favorito === true,
        );

        if (companyFavorite) setFavoriteCompany(companyFavorite.id);

        setUserCompanies(response.data);
      } catch (err) {
        toast.error(
          'Ocorreu um erro ao tentar buscar as empresas, tente novamente!',
          { delay: 300 },
        );
      }
    }

    user && searchCompany();
  }, [user]);

  return (
    <>
      <Container>
        <AnimationContainer>
          <img className="logo-system" src={logoInsight} alt="logo-insight" />

          <h1>Qual empresa você deseja?</h1>

          <Content>
            {userCompanies ? (
              userCompanies.map(({ empresa, favorito, id }) => (
                <div key={empresa.id}>
                  <Avatar
                    onClick={() =>
                      handleSelectCompany({ empresa, favorito, id })
                    }
                  >
                    <img
                      src={
                        empresa.avatar_url
                          ? empresa.avatar_url
                          : generateAvatars(empresa.nome_fantasia)
                      }
                      alt="Avatar da empresa"
                    />
                  </Avatar>

                  <AvatarConfig>
                    <Favorite
                      isFavorite={favoriteCompany === id}
                      onClick={() => handleUpdateFavoriteCompany(id)}
                    >
                      <FaStar />
                    </Favorite>
                    <div>
                      <FaEdit
                        className="edit-icon"
                        onClick={() => handleEditCompany(empresa)}
                      />
                    </div>
                  </AvatarConfig>

                  <button
                    type="button"
                    onClick={() =>
                      handleSelectCompany({ empresa, favorito, id })
                    }
                  >
                    <h3>{empresa.nome_fantasia}</h3>
                  </button>
                </div>
              ))
            ) : (
              <Skeleton height="8rem" width="8rem" circle />
            )}
          </Content>
        </AnimationContainer>
      </Container>
      <UploadAvatar
        isOpen={isNewModalOpen}
        onRequestClose={handleCloseNewModal}
        fieldName="avatar"
        apiUrl={`/company/image/avatar/${companyEdit?.id}`}
        imageUrl={companyEdit?.avatar_url}
        name={companyEdit?.nome_fantasia}
      />
    </>
  );
}
