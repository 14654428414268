import styled, { css, keyframes } from 'styled-components';
import { transparentize } from 'polished';

interface IIsSubMenuActive {
  isSidebarExpanded: boolean;
  isSubMenuActive?: boolean;
  isSubMenuSelected?: boolean;
  menuClassName?: string;
}

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const MenuOptions = styled.ul`
  width: 100%;

  .content-menu:hover {
    button {
      ${({ theme }) => css`
        border-left: ${`5px solid ${theme.colors.ice}`};
        background: ${theme.title === 'dark'
          ? theme.colors.grayGradient
          : theme.colors.darkBlueGradient};
      `}
    }

    ul {
      transform: translateX(52px);
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const Content = styled.li<IIsSubMenuActive>`
  display: flex;
  flex-direction: column;

  .menu-selected {
    ${({ theme }) => css`
      background: ${transparentize(
        0.5,
        theme.title === 'dark' ? theme.colors.darkBlue : theme.colors.ice,
      )};
      border-left: 4px solid ${theme.colors.ice};
    `}
  }

  .menu-select {
    background: transparent;
  }

  button {
    background: transparent;
    border: 0;
    height: 48px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    margin: 4px 0px;
    padding: 0px 16px 0px 16px;
    transition: all 0.2s ease-in-out;

    ${({ theme }) => css`
      svg {
        color: ${theme.colors.ice};
      }

      &:hover {
        border-left: ${`5px solid ${theme.colors.ice}`};
        background: ${theme.colors.darkBlue};
        filter: brightness(1.3);
      }
    `}

    .menu-arrow {
      font-size: 1.2rem;
    }
  }
  ${({ isSubMenuActive, menuClassName }) => css`
    .${menuClassName} {
      display: ${isSubMenuActive && 'block'};
      visibility: ${isSubMenuActive ? 'visible' : 'hidden'};
      opacity: ${isSubMenuActive ? '1' : '0'};
      animation: ${appearFromTop} 0.3s ease-in-out;
    }
  `}
`;

export const MenuTitle = styled.div<IIsSubMenuActive>`
  display: flex;
  align-items: center;
  font-size: 1rem;

  ${({ theme, isSidebarExpanded }) => css`
    color: ${theme.colors.ice};

    .menu-title {
      display: ${isSidebarExpanded ? 'inline' : 'none'};
    }

    .menu-icon {
      display: flex;
      align-items: center;
      font-size: 1rem;
      margin-right: 12px;
    }
  `}
`;

export const SubMenu = styled.ul<IIsSubMenuActive>`
  visibility: hidden;
  opacity: 0;

  ${({ isSidebarExpanded, theme }) => css`
    position: ${!isSidebarExpanded && 'absolute'};
    height: ${!isSidebarExpanded && 'auto'};
    background: ${!isSidebarExpanded
      ? theme.title === 'dark'
        ? theme.colors.backgroundIndex
        : theme.colors.blueGradient
      : 'transparente'};
    box-shadow: ${!isSidebarExpanded && theme.boxShadow};
    transition: ${!isSidebarExpanded && 'all 0.3s ease-in-out'};
    transform: ${!isSidebarExpanded && 'translateX(48px)'};
    width: ${!isSidebarExpanded && '220px'};
    display: ${isSidebarExpanded && 'none'};
  `}
`;

export const SubMenuList = styled.li<IIsSubMenuActive>`
  list-style: none;

  div {
    position: relative;
    height: 48px;
    transition: all 0.2s ease-in;
  }

  a {
    ${({ isSidebarExpanded }) => css`
      padding: 0px 8px 0px ${isSidebarExpanded ? '48px' : '24px'};
    `}

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${({ theme, isSubMenuSelected }) => css`
    ${!isSubMenuSelected &&
    `
        &:hover {
          border-left: ${`4px solid ${theme.colors.ice}`};
          background: ${theme.colors.darkBlue};
          filter: brightness(1.3);
        }
    `}
  `}
`;

export const SubMenuTitle = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.colors.ice};
  `}

  svg {
    margin-right: 12px;
  }
`;
