import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    background: ${theme.colors.background};
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  padding: 2rem;
`;

export const ImageNotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 4rem;
    width: 100%;
    max-width: 36rem;
  }
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  max-width: 260px;
  margin-top: 4rem;

  ${({ theme }) => css`
    color: ${theme.title === 'dark' ? theme.colors.ice : theme.colors.darkBlue};
  `};

  strong {
    font-size: 1rem;
  }
`;
