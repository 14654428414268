import { Link, useLocation } from 'react-router-dom';
import { FiUser, FiSettings, FiPower, FiRefreshCw } from 'react-icons/fi';

import { Can } from 'components/Can';

import { useAuth } from 'hooks/auth';

import { useEffect } from 'react';
import { Container } from './styles';

interface INewModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const UserMenu = ({ isOpen, onRequestClose }: INewModalProps) => {
  const { signOut, user } = useAuth();
  const { pathname } = useLocation();

  function handleLogOut() {
    signOut();
  }

  useEffect(() => {
    onRequestClose();
  }, [pathname]);

  return (
    <Container
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-menu-user-overlay"
      bodyOpenClassName="react-modal-menu-user-open"
    >
      <Link to="/profile">
        <span>Perfil</span>
        <div>
          <FiUser />
        </div>
      </Link>

      {user && user.usuario_empresa.length > 1 && (
        <Link to="/company-selection">
          <span>Alterar empresa</span>
          <div>
            <FiRefreshCw />
          </div>
        </Link>
      )}

      <Can roles={['Administrador', 'Moderador']}>
        <Link to="/configurations">
          <span>Configurações</span>
          <div>
            <FiSettings />
          </div>
        </Link>
      </Can>

      <button type="button" onClick={handleLogOut}>
        <span>Sair</span>
        <div>
          <FiPower />
        </div>
      </button>
    </Container>
  );
};

export default UserMenu;
