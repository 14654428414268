/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError } from 'axios';

const token = localStorage.getItem('@Insight:token');

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_USER_MANAGEMENT,
  headers: {
    authorization: `Bearer ${token}`,
  },
});

export const webBff = axios.create({
  baseURL: process.env.REACT_APP_API_WEB_BFF,
  headers: {
    authorization: `Bearer ${token}`,
  },
});

export const newApiFake = axios.create({
  baseURL: process.env.REACT_APP_API_FAKE_URL,
});
