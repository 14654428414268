import CompanySelection from 'pages/CompanySelection';
import Profile from 'pages/Configurations/Profile';

import { IRouteProperties } from 'models/IRoutes';

export const noLayoutRoutes: IRouteProperties[] = [
  {
    component: Profile,
    exact: true,
    path: '/profile',
  },
  {
    component: CompanySelection,
    exact: true,
    path: '/company-selection',
  },
];
