import styled, { css } from 'styled-components';

interface IButtonProps {
  color?: string;
  size?: string;
}

export const Container = styled.button<IButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ color, theme, size }) => css`
    background: ${color ||
    (theme.title === 'dark'
      ? theme.colors.grayGradient
      : theme.colors.darkBlueGradient)};
    color: ${theme.colors.ice};
    height: ${size || '3.5rem'};
  `}

  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  &:hover {
    ${({ color, theme }) => css`
      background: ${color ||
      (theme.title === 'dark'
        ? theme.colors.grayGradient
        : theme.colors.darkBlueGradient)};
      color: ${theme.colors.ice};
    `}

    filter: brightness(1.1);
  }

  svg {
    ${({ theme }) => css`
      stroke: ${theme.colors.ice};
    `}
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
