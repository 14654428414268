import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-150px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const ModalContainer = styled(Modal)`
  position: absolute;
  width: 255px;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  left: 0;

  background: ${props => props.theme.colors.backgroundBox};

  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: 769px) {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  h2 {
    color: ${props =>
      props.theme.title === 'dark'
        ? props.theme.colors.ice
        : props.theme.colors.darkBlue};
    font-size: clamp(0.6rem, 0.8rem + 1vw, 1.5rem);

    margin-bottom: 2rem;
    margin-top: -1rem;

    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
  }

  .react-modal-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: 0;
    background: transparent;
    transition: filter 0.2s;
    z-index: 2;
    animation: ${appearFromLeft} 0.3s ease-in-out;

    &:hover {
      filter: brightness(0.5);
    }

    svg {
      font-size: 1rem;
      color: ${props => props.theme.colors.ice};
    }
  }
`;

export const Content = styled.div`
  height: 100%;
`;
