import { useRef, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from 'hooks/auth';
import { useTheme } from 'hooks/theme';

import getValidationErrors from 'utils/getValidationErrors';

import Input from 'components/Input';
import Button from 'components/Button';

import logoInsight from 'assets/images/logo.svg';
import backgroundDark from 'assets/images/login-background-dark.svg';
import backgroundLight from 'assets/images/login-background-light.svg';

import {
  Container,
  Content,
  AnimationContainer,
  Section,
  Background,
} from './styles';

interface ISignInFormData {
  email: string;
  senha: string;
}

export default function SignIn() {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const { signIn } = useAuth();
  const { theme } = useTheme();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: ISignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('E-mail inválido'),
          senha: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        await signIn({
          email: data.email,
          senha: data.senha,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          toast.error('Email ou senha inválidos.', { delay: 400 });
        }

        setLoading(false);
      }
    },
    [signIn, history],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoInsight} alt="logo-insight" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu login</h1>

            <Section>
              <Input
                name="email"
                icon={FiMail}
                placeholder="E-mail"
                inputSize="1rem"
                autoComplete="false"
              />

              <Input
                name="senha"
                icon={FiLock}
                type="password"
                placeholder="Senha"
                inputSize="1rem"
                autoComplete="false"
              />
            </Section>

            <Button type="submit" loading={loading}>
              ENTRAR
            </Button>

            <Link to="/forgot-password">Esqueci a minha senha</Link>
          </Form>
        </AnimationContainer>
      </Content>
      <Background>
        <img
          src={theme.title === 'dark' ? backgroundDark : backgroundLight}
          alt="background-login"
        />
      </Background>
    </Container>
  );
}
