import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';

import { useTheme } from 'hooks/theme';

import Button from 'components/Button';
import Input from 'components/Input';

import getValidationErrors from 'utils/getValidationErrors';

import { api } from 'services/api';

import { Content, ButtonContainer } from './styles';

type typeCurrentLicenses = {
  empresaId: string;
  total: number;
  inUse: number;
};

type componentProps = {
  licenses: typeCurrentLicenses;
  onRequestClose: () => void;
};

interface IFormData {
  quantidade_licenca: number;
}

export default function LicenceUpdate({
  licenses,
  onRequestClose,
}: componentProps) {
  const formRef = useRef<FormHandles>(null);
  const { theme } = useTheme();

  const { colors } = theme;

  const [loading, setLoading] = useState(false);

  const handleSubmit: SubmitHandler<IFormData> = async (data, { reset }) => {
    try {
      const schema = Yup.object().shape({
        quantidade_licenca: Yup.number()
          .transform(value => value || 0)
          .min(
            licenses.inUse,
            `Não é possível ter menos licenças do que em uso`,
          )
          .required('Licenças obrigatórias.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      await api.put(`company/license/${licenses.empresaId}`, {
        licenseAmount: data.quantidade_licenca,
      });

      reset();
      onRequestClose();
      toast.success('Quantidade de licenças salva com sucesso!');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        toast.error(
          'Ocorreu um erro ao tentar gravar os dados, tente novamente!',
          { delay: 400 },
        );
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    formRef.current?.setData({
      quantidade_licenca: licenses.total,
      quantidade_licenca_em_uso: licenses.inUse,
    });
  }, []);

  return (
    <>
      <h2>Alterar a quantidade de licenças</h2>
      <Content ref={formRef} onSubmit={handleSubmit}>
        <section>
          <Input
            name="quantidade_licenca"
            label="Licenças Disponíveis"
            placeholder="Licenças"
            requiredField
            type="number"
          />
          <Input
            name="quantidade_licenca_em_uso"
            label="Licenças em uso"
            disabled
          />
        </section>

        <ButtonContainer>
          <Button
            type="button"
            color={colors.redGradient}
            onClick={onRequestClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button type="submit" color={colors.greenGradient} loading={loading}>
            Gravar
          </Button>
        </ButtonContainer>
      </Content>
    </>
  );
}
