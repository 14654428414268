import axios from 'axios';
import {
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';

import { api, webBff } from '../services/api';

interface IPropsChildren {
  children: ReactNode;
}

type Company = {
  avatar_url: string;
  logo_url: string;
};

interface ICompanyUser {
  id: string;
  empresa_id: string;
  favorito: boolean;
  empresa: Company;
}

interface IUser {
  id: string;
  nome: string;
  email: string;
  avatar_url: string;
  status: boolean;
  admin: boolean;
  usuario_empresa: ICompanyUser[];
  cargos: string[];
}

interface IAuthState {
  token: string;
  user: IUser;
  firstLogin?: boolean;
}

interface ISignInCredentials {
  email: string;
  senha: string;
}

interface IAuthContextData {
  user?: IUser;
  isAuthenticated: boolean;
  handleUserRender(): Promise<void>;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
  firstLogin: boolean;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

let authChannel: BroadcastChannel;

const AuthProvider = ({ children }: IPropsChildren) => {
  const history = useHistory();

  const [userData, setUserData] = useState<IUser | undefined>();
  const [firstLogin, setFirstLogin] = useState<boolean>(() => {
    const validateFirstLogin = localStorage.getItem('@Insight:first-login');

    if (validateFirstLogin) return validateFirstLogin === 'true';

    return true;
  });

  const isAuthenticated = !!userData;

  useEffect(() => {
    authChannel = new BroadcastChannel('auth');

    authChannel.onmessage = message => {
      switch (message.data) {
        case 'signOut':
          history.push('/');
          break;
        default:
          break;
      }
    };
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async () => {
      const token = localStorage.getItem('@Insight:token');

      if (token) {
        await handleUserRender();
      }
    })();

    return () => {
      source.cancel();
    };
  }, []);

  const handleUserRender = async () => {
    await api
      .get<IUser>('/profile/me')
      .then(response => {
        setUserData(response.data);
      })
      .catch(() => {
        localStorage.removeItem('@Insight:token');

        history.push('/');
      });
  };

  const signIn = useCallback(async ({ email, senha }) => {
    const response = await api.post<IAuthState>('sessions', { email, senha });

    const { token, user } = response.data;

    localStorage.setItem('@Insight:token', token);

    const validateFirstLogin = localStorage.getItem('@Insight:first-login');

    if (!validateFirstLogin)
      localStorage.setItem('@Insight:first-login', JSON.parse('true'));

    setUserData(user);

    api.defaults.headers.authorization = `Bearer ${token}`;
    webBff.defaults.headers.authorization = `Bearer ${token}`;

    history.push('/home');
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Insight:token');

    const validateFirstLogin = localStorage.getItem('@Insight:first-login');

    if (validateFirstLogin === 'true')
      localStorage.setItem('@Insight:first-login', JSON.parse('false'));

    setUserData(undefined);
    setFirstLogin(false);

    authChannel.postMessage('signOut');

    history.push('/');
  }, []);

  const updateUser = useCallback((user: IUser) => {
    setUserData(user);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: userData,
        handleUserRender,
        signIn,
        signOut,
        updateUser,
        firstLogin,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
