import styled from 'styled-components';
import AvatarEditor from 'react-avatar-editor';
import { Form as FormWeb } from '@unform/web';

export const Container = styled.div`
  width: 768px;
  max-width: 100%;

  h1 {
    font-size: 1.5rem;
  }
`;

export const Form = styled(FormWeb)`
  display: flex;
  flex-direction: column;

  footer {
    padding-top: 16px;
    border-top: 1px solid ${props => props.theme.colors.lightGray};

    div {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      div:first-child {
        width: 50%;
      }

      @media (max-width: 768px) {
        width: 100% !important;
        flex-direction: column;
        align-items: center;

        button {
          margin: 0;
        }
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  margin: 24px 0;

  .logo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    width: 100%;

    span {
      margin-top: 16px;
      font-size: 0.85rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const AvatarInput = styled(AvatarEditor)`
  position: relative;
  align-self: center;
  margin: 0px 34px;

  cursor: grabbing;
  touch-action: none;
`;

export const ContentInputRange = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  div {
    margin: 16px 0px;
    display: flex;
    flex-direction: column;

    input[type='range'] {
      width: 100%;
      height: 2px;
      margin-top: 8px;
      padding: 0px;
      border: none;
      background: ${props => props.theme.colors.lightGray};
      appearance: none;
    }

    input[type='range']::-webkit-slider-thumb {
      appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;

      background: ${props => props.theme.colors.cyan};
      cursor: pointer;
    }

    input[type='range']::-moz-range-thumb {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 0;

      background: ${props => props.theme.colors.cyan};
      cursor: pointer;
    }
  }
`;
