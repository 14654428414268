import styled from 'styled-components';

export const Container = styled.div`
  width: 32rem;

  @media (max-width: 468px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;

  span {
    color: red;
  }

  div {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 1rem;

    @media (max-width: 768px) {
      grid-auto-flow: row;
      gap: 0;
    }
  }
`;
