import { useEffect, useState } from 'react';
import { Column } from '@material-table/core';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { toast } from 'react-toastify';

import { useCompany } from 'hooks/company';
import { useAuth } from 'hooks/auth';
import { useTheme } from 'hooks/theme';

import Table from 'components/Table';
import Tooltip from 'components/Tooltip';
import { NewModal } from 'components/Modal';
import { WarningMessage, IMessages } from 'components/WarningMessage';
import { Card, CardGrid } from 'components/Card';

import { api } from 'services/api';

import { ITableActions, IFormAction } from 'models/ITableActions';

import CrudUser from './Crud';

import { Section, ContentRole, Status } from './styles';

interface IUser {
  id: string;
  status: boolean;
  email: string;
  usuario_cargo: [
    {
      cargo: {
        nome: string;
      };
    },
  ];
}

interface IRowData extends Column<IRowData[]> {
  usuario?: IUser;
}

export default function User() {
  const { theme } = useTheme();
  const { companyUser } = useCompany();
  const { user } = useAuth();

  const { colors } = theme;

  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isNewWarningAction, setIsNewWarningAction] = useState(false);
  const [userData, setUserData] = useState<IRowData[]>([]);
  const [userStatusUpdate, setUserStatusUpdate] = useState<IUser>();
  const [loading, setLoading] = useState(false);
  const [typeOfUserAction, setTypeOfUserAction] = useState<IFormAction>({
    typeOfAction: 'view',
  });
  const [columns, setColumns] = useState<Column<IRowData>[]>([]);
  const [message, setMessage] = useState<IMessages>({
    title: '',
  });
  const [licenses, setLicenses] = useState({
    bought: 0,
    use: 0,
    available: 0,
  });
  const [isStatusUpdate, setIsStatusUpdate] = useState<boolean>(false);
  const [isCreateActionDisabled, setIsCreateActionDisabled] =
    useState<boolean>(false);

  function handleOpenNewUserModal() {
    setIsNewUserModalOpen(true);
  }

  function handleCloseNewUserModal() {
    setTypeOfUserAction({ id: '', typeOfAction: 'view' });
    setIsNewUserModalOpen(false);
  }

  function handleCloseNewWarningAction() {
    if (!loading) setIsNewWarningAction(false);
  }

  function createUser() {
    setTypeOfUserAction({ typeOfAction: 'include' });
    handleOpenNewUserModal();
  }

  function updateUser(dataUpdate: any) {
    setTypeOfUserAction({
      id: dataUpdate.usuario.id,
      typeOfAction: 'update',
    });
    handleOpenNewUserModal();
  }

  function deleteUser(dataDelete: any) {
    setTypeOfUserAction({
      id: dataDelete.usuario.id,
      typeOfAction: 'delete',
    });
    handleOpenNewUserModal();
  }

  function handleStatusClick(dataUser: IUser | undefined) {
    if (dataUser) {
      if (dataUser.status)
        setMessage({
          title: `Deseja realmente desativar ${dataUser?.email}?`,
        });
      else
        setMessage({
          title: `Deseja realmente ativar ${dataUser?.email}?`,
          description:
            'Iremos enviar um e-mail para o usuário realizar o cadastro de uma nova senha',
        });

      setUserStatusUpdate(dataUser);
      setIsNewWarningAction(true);
    }
  }

  async function handleUpdateStatus() {
    setLoading(true);

    if (userStatusUpdate?.status) {
      await api
        .put(`users/status/${userStatusUpdate.id}`)
        .then(() => {
          const users = userData.map(item =>
            item.usuario?.id === userStatusUpdate.id
              ? { ...item, usuario: { ...item.usuario, status: false } }
              : item,
          );
          setIsStatusUpdate(true);
          setUserData(users);
        })
        .catch(() => {
          toast.error(
            'Ocorreu um problema ao tentar desativar efetuar a desativação.',
          );
        });
    } else if (userStatusUpdate) {
      toast.info('Aguarde, estamos enviando um e-mail de ativação!');

      await api
        .post('/activation/', {
          email: userStatusUpdate.email,
          empresa_id: companyUser.empresa_id,
        })
        .then(() => {
          toast.success('Enviamos um e-mail de ativação para o colaborador!');
        })
        .catch(() => {
          toast.error('Ocorreu um problema ao tentar efetuar a desativação.');
        });
    }
    setLoading(false);
    handleCloseNewWarningAction();
  }

  useEffect(() => {
    async function getUser() {
      await Promise.all([
        api.get(
          `company-user/list-all-users-company/${companyUser.empresa_id}/${user?.admin}`,
        ),
        api.get(`company/${companyUser.empresa_id}`),
      ])
        .then(response => {
          const [users, licenseQuantity] = response;

          const { quantidade_licenca, quantidade_licenca_em_uso } =
            licenseQuantity.data;

          const available = quantidade_licenca - quantidade_licenca_em_uso;

          if (available === 0) setIsCreateActionDisabled(true);
          else setIsCreateActionDisabled(false);

          const columnsData: Column<IRowData>[] = [
            { title: 'NOME', field: 'usuario.nome' },
            { title: 'EMAIL', field: 'usuario.email' },
            {
              title: 'CARGO',
              render: rowData => {
                const { usuario } = rowData;

                const roles = usuario?.usuario_cargo.map(item => (
                  <div key={item.cargo.nome}>{item.cargo.nome}</div>
                ));

                return <ContentRole>{roles}</ContentRole>;
              },
            },
            {
              title: 'STATUS',
              field: 'usuario.status',
              align: 'center',
              editable: 'onUpdate',
              render: rowData =>
                rowData.usuario?.status ? (
                  <Tooltip
                    title="Clique para desativar o usuário"
                    fontSize="0.625rem"
                    responsiveModel={false}
                  >
                    <Status
                      onClick={() => handleStatusClick(rowData.usuario)}
                      statusColor={theme.colors.lightGreen}
                    >
                      ATIVO
                    </Status>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title="Clique para ativar o usuário"
                    fontSize="0.625rem"
                    responsiveModel={false}
                  >
                    <Status
                      onClick={() => handleStatusClick(rowData.usuario)}
                      statusColor={theme.colors.lightRed}
                      disabled={available === 0}
                    >
                      INATIVO
                    </Status>
                  </Tooltip>
                ),
            },
          ];

          setLicenses({
            bought: quantidade_licenca,
            use: quantidade_licenca_em_uso,
            available,
          });

          setUserData(users.data);
          setColumns(columnsData);
        })
        .catch(() => {
          toast.error(
            'Ocorreu um problema ao tentar carregar a listagem de usuários, tente novamente!',
          );
        });
    }
    !isNewUserModalOpen && user && getUser();
  }, [isNewUserModalOpen, isStatusUpdate, user]);

  const tableActions: ITableActions = {
    create: createUser,
    update: updateUser,
    delete: deleteUser,
  };

  return (
    <>
      <Breadcrumbs />
      <CardGrid numberOfColumns={3}>
        <Card
          title="Licenças compradas"
          description="Quantidade de licenças compradas"
          value={licenses.bought}
          containsAnimation
        />
        <Card
          title="Licenças disponíveis"
          description="Quantidade de licenças restante"
          value={licenses.available}
          backgroundColor={colors.greenGradient}
          containsAnimation
        />
        <Card
          title="Licenças em uso"
          description="Quantidade de licenças em uso"
          value={licenses.use}
          backgroundColor={colors.redGradient}
          containsAnimation
        />
      </CardGrid>
      <Section>
        <Table
          componentData={userData}
          columnsData={columns}
          tableActions={tableActions}
          height="calc(100vh - 29rem)"
          isCreateActionDisabled={isCreateActionDisabled}
        />
      </Section>
      <NewModal
        isOpen={isNewUserModalOpen}
        onRequestClose={handleCloseNewUserModal}
      >
        <CrudUser
          action={typeOfUserAction}
          onRequestClose={handleCloseNewUserModal}
        />
      </NewModal>
      <WarningMessage
        message={message}
        handleCloseNewWarningAction={handleCloseNewWarningAction}
        isNewWarningAction={isNewWarningAction}
        handleConfirmation={handleUpdateStatus}
        loading={loading}
      />
    </>
  );
}
