import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  width: 40rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Content = styled(Form)`
  section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1rem;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: column;
      gap: 0.5rem;
    }

    @media (max-width: 468px) {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;

  span {
    color: red;
  }

  div {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 1rem;

    @media (max-width: 768px) {
      grid-auto-flow: row;
      gap: 0;
    }
  }
`;
