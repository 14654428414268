import { RolesValidation } from 'models/IRoles';

import { useAuth } from './auth';

type UseCanParams = {
  roles?: RolesValidation;
};

export function useCan({ roles }: UseCanParams) {
  const { user, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return false;
  }

  if (roles && roles?.length > 0) {
    const hasAllRoles = roles?.some(role => user?.cargos?.includes(role));

    if (!hasAllRoles) {
      return false;
    }
  }

  return true;
}
