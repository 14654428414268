import styled, { keyframes } from 'styled-components';

const effectLoading = keyframes`
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  animation: ${effectLoading} 1s infinite alternate-reverse;

  img {
    width: 8rem;
    height: 8rem;
  }
`;
