import { ReactNode } from 'react';
import { FiX } from 'react-icons/fi';

import { ModalContainer, Content } from './styles';

interface INewModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  children: ReactNode;
}

export function NewModal({ isOpen, onRequestClose, children }: INewModalProps) {
  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-filter-overlay"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <FiX />
      </button>

      <Content>{children}</Content>
    </ModalContainer>
  );
}
