import Layout from 'components/Layout';
import { noLayout } from 'components/NoLayout';

import { IRouteTemplateProperties } from 'models/IRoutes';
import { RouteTypeEnum } from './routeTypes';

import { sessionsRoutes } from './public';
import { configurationsRoutes, modulesRoutes, noLayoutRoutes } from './private';

const routesTemplate: IRouteTemplateProperties[] = [
  {
    routes: configurationsRoutes,
    template: Layout,
    type: RouteTypeEnum.private,
  },
  {
    routes: modulesRoutes,
    template: Layout,
    type: RouteTypeEnum.private,
  },
  {
    routes: noLayoutRoutes,
    template: noLayout,
    type: RouteTypeEnum.private,
  },
  {
    routes: sessionsRoutes,
    template: noLayout,
    type: RouteTypeEnum.session,
  },
];
export default routesTemplate;
