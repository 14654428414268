import { ChangeEvent, useRef, useState, useEffect } from 'react';
import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import { api } from 'services/api';

import { useTheme } from 'hooks/theme';

import { NewModal } from 'components/Modal';
import Button from 'components/Button';

import logoCustomer from 'assets/images/logo-customer.svg';

import {
  Container,
  AvatarInput,
  Form,
  Content,
  ContentInputRange,
} from './styles';

interface IUploadImageProps {
  isOpen: boolean;
  onRequestClose: () => void;
  fieldName: string;
  apiUrl: string;
  imageUrl?: string;
}

export function UploadLogo({
  isOpen,
  onRequestClose,
  fieldName,
  apiUrl,
  imageUrl,
}: IUploadImageProps) {
  const { theme } = useTheme();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<FormHandles>(null);
  const editor = useRef<AvatarEditor>(null);

  const allowZoomOut = false;

  const [isUploadNotEnabled, setIsUploadNotEnabled] = useState(true);
  const [avatarEditor, setAvatarEditor] = useState<AvatarEditorProps>(
    {} as AvatarEditorProps,
  );

  useEffect(() => {
    setAvatarEditor({
      image: imageUrl || logoCustomer,
      scale: 1.0,
      rotate: 0,
    });
    setIsUploadNotEnabled(true);
  }, [isOpen]);

  const handleAvatarChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        const image = e.target.files[0];

        const { size } = image;

        const maxSize = 2 * 1000 * 1000;

        if (size > maxSize) {
          toast.error('Tamanho da logo acima do suportado: 2.0 MB', {
            delay: 400,
          });

          return;
        }

        setAvatarEditor({ ...avatarEditor, image });
        setIsUploadNotEnabled(false);
      }
    } catch {
      toast.error('Erro ao tentar fazer o upload da logo, tente novamente!', {
        delay: 200,
      });
    }
  };

  const handleScale = (scale: number) => {
    setAvatarEditor({ ...avatarEditor, scale });
  };

  const handleRotate = (rotate: number) => {
    setAvatarEditor({ ...avatarEditor, rotate });
  };

  const handleDeleteImage = () => {
    setAvatarEditor({ ...avatarEditor, image: logoCustomer });
    setIsUploadNotEnabled(false);
  };

  const handleNewImage = async () => {
    const canvas = editor.current?.getImage();

    try {
      canvas?.toBlob(async blob => {
        const data = new FormData();
        if (blob) {
          const file = new File([blob], 'logo.png', {
            type: 'image/png',
          });

          data.append(fieldName, file);

          await api.patch(apiUrl, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          toast.success('Logo atualizado!');
          onRequestClose();
        }
      });
    } catch {
      toast.error('Erro ao tentar atualizar a logo, tente novamente!', {
        delay: 200,
      });
    }
  };

  return (
    <NewModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Container>
        <h1>Editar foto</h1>

        <Form onSubmit={handleNewImage} ref={formRef}>
          <input
            id="fileButton"
            type="file"
            hidden
            ref={fileInputRef}
            onChange={handleAvatarChange}
          />
          <Content>
            <div className="logo">
              <AvatarInput
                image={avatarEditor.image}
                width={152}
                height={24}
                border={0}
                scale={avatarEditor.scale}
                rotate={avatarEditor.rotate}
                ref={editor}
              />
              <span>Tamanho recomendado: 152x24</span>
              {!isUploadNotEnabled && <span>Arraste para posicionar</span>}
            </div>
            <ContentInputRange>
              <div>
                <label htmlFor="zoom">Zoom</label>
                <input
                  id="zoom"
                  name="scale"
                  type="range"
                  min={allowZoomOut ? '0.1' : '0.7'}
                  max="2"
                  step="0.01"
                  defaultValue="1"
                  onChange={e => handleScale(parseFloat(e.target.value))}
                  disabled={isUploadNotEnabled}
                />
              </div>
              <div>
                <label htmlFor="zoom">Rotacionar</label>
                <input
                  name="rotate"
                  type="range"
                  min="-45"
                  max="45"
                  step="0.01"
                  defaultValue="0"
                  onChange={e => handleRotate(parseFloat(e.target.value))}
                  disabled={isUploadNotEnabled}
                />
              </div>
            </ContentInputRange>
          </Content>
          <footer>
            <div>
              <div>
                <Button
                  type="button"
                  onClick={() => handleDeleteImage()}
                  color={theme.colors.redGradient}
                >
                  Excluir foto
                </Button>
                <Button
                  type="button"
                  onClick={() => fileInputRef?.current?.click()}
                  color={theme.colors.blueGradient}
                >
                  Alterar foto
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  color={theme.colors.greenGradient}
                  disabled={isUploadNotEnabled}
                >
                  Confirmar alteração
                </Button>
              </div>
            </div>
          </footer>
        </Form>
      </Container>
    </NewModal>
  );
}
