import { Link } from 'react-router-dom';

import imageNoPermission from 'assets/images/no-permission.svg';

import { Container, Text } from './styles';

export function NoPermission() {
  return (
    <Container>
      <Text>
        <h1>Sem permissão</h1>
        <div>
          <strong>
            Desculpe, mas você não tem permissão para acessar esta página
          </strong>
          <strong>
            Você pode retornar para a <Link to="/">home</Link>
          </strong>
        </div>
      </Text>

      <img src={imageNoPermission} alt="Sem permissão" />
    </Container>
  );
}
