import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-right: 2px solid;
  padding-right: 1rem;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    img {
      height: 24px;
    }

    .icon-edit {
      cursor: pointer;
      margin: 0 0 8px 4px;

      font-size: 0.8rem;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 368px) {
    display: none;
  }
`;
