import Button from 'components/Button';
import { useHistory } from 'react-router-dom';

import imageNotFound from 'assets/images/not-found.svg';

import { Container, Content, ImageNotFound, Text } from './styles';

export function NotFound() {
  const history = useHistory();

  return (
    <Container>
      <Content>
        <ImageNotFound>
          <img src={imageNotFound} alt="Página não encontrada" />
        </ImageNotFound>
        <Text>
          <h1>404...</h1>
          <strong>Página não encontrada.</strong>
          <Button
            onClick={() => {
              history.push('/');
            }}
          >
            Retornar à home
          </Button>
        </Text>
      </Content>
    </Container>
  );
}
