/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode, createContext, useContext, useState } from 'react';
import { format } from 'date-fns';

import { IFiltersState, FilterOptionsVisible } from 'models/IFilter';
import { generateDateArrayByPeriod, lastDay } from '../utils/dateTreatment';

interface IFilterProviderProps {
  children: ReactNode;
}

interface IFilterContextData {
  filters: IFiltersState;
  filtersRender: FilterOptionsVisible;
  handleInsert: ({
    initialDate,
    finalDate,
    typeTitle,
    client,
    seller,
    coordinator,
  }: IFiltersState) => void;
  handleGraphicalDatePeriod: () => string[];
  handleGraphicalMonthPeriod: () => string[];
}

const FilterContext = createContext<IFilterContextData>(
  {} as IFilterContextData,
);

const FilterProvider = ({ children }: IFilterProviderProps) => {
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const currentMonth = format(new Date(), 'yyyy-MM');

  const [yearNow, monthNow] = currentDate.split('-');

  const [filters, setFilters] = useState<IFiltersState>({
    initialDate: `${yearNow}-${parseInt(monthNow, 10) - 1}-01`,
    finalDate: currentDate,
    month: currentMonth,
    graphicalPeriod: [],
    client: undefined,
    seller: undefined,
    coordinator: undefined,
    typeTitle: undefined,
  });

  const [filtersRender, setFiltersRender] = useState<FilterOptionsVisible>({
    isClients: false,
    isSellers: false,
    isCoordinators: false,
    isSurveyByMonth: false,
    isTypeTitles: false,
  });

  const handleInsert = ({
    initialDate,
    finalDate,
    month,
    ...rest
  }: IFiltersState) => {
    if (month) {
      setFilters({
        ...filters,
        month: format(new Date(month), 'yyyy-MM'),
        ...rest,
      });
    } else if (initialDate && finalDate) {
      setFilters({
        ...filters,
        initialDate: format(new Date(initialDate), 'yyyy-MM-dd'),
        finalDate: format(new Date(finalDate), 'yyyy-MM-dd'),
        ...rest,
      });
    }
  };

  const handleGraphicalDatePeriod = () => {
    const { finalDate } = filters;
    let [year, month] = currentDate.split('-');

    if (finalDate !== undefined) {
      [year, month] = finalDate.split('-');
    }

    const initialDate = `${parseInt(year, 10) - 1}-${month}-01`;

    const periodArray = generateDateArrayByPeriod(
      initialDate,
      finalDate || currentDate,
    );

    return periodArray;
  };

  const handleGraphicalMonthPeriod = () => {
    const { month: monthAndYear } = filters;
    let [year, month] = currentDate.split('-');

    if (monthAndYear !== undefined) {
      [year, month] = monthAndYear.split('-');
    }

    const initialDate = `${parseInt(year, 10) - 1}-${month}-01`;
    const finalDate = lastDay(parseInt(year, 10), parseInt(month, 10));

    const periodArray = generateDateArrayByPeriod(initialDate, finalDate);

    return periodArray;
  };

  return (
    <FilterContext.Provider
      value={{
        filters,
        filtersRender,
        handleInsert,
        handleGraphicalDatePeriod,
        handleGraphicalMonthPeriod,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

function useFilter(): IFilterContextData {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFilter must be used within an FilterProvider');
  }

  return context;
}

export { FilterProvider, useFilter };
