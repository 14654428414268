import { Can } from 'components/Can';
import { CardConfig } from 'components/Card';

import { ConfigurationsData } from 'data/configurations';

import { Container } from './styles';

export default function Configurations() {
  const configurationsData = ConfigurationsData();

  return (
    <Container>
      {configurationsData.map(
        ({
          title,
          titleColor,
          description,
          icon,
          iconColor,
          path,
          rolePermissions,
        }) => (
          <Can roles={rolePermissions} key={path}>
            <CardConfig
              title={title}
              titleColor={titleColor}
              description={description}
              icon={icon}
              iconColor={iconColor}
              path={path}
              rolePermissions={rolePermissions}
            />
          </Can>
        ),
      )}
    </Container>
  );
}
