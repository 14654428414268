import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.header`
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 45px;

  grid-area: header;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px 41px 0 48px;

  ${({ theme }) => css`
    background: ${theme.title === 'dark'
      ? theme.colors.backgroundBox
      : theme.colors.ice};
    box-shadow: ${theme.boxShadow};
  `}

  @media screen and (max-width: 768px) {
    padding: 0px 16px;
  }
`;

export const CollapsedMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.title === 'dark' ? theme.colors.ice : theme.colors.darkBlue};
  `};

  &:hover {
    opacity: 0.8;
  }

  @media screen and (min-width: 769px) {
    svg {
      display: none;
    }
  }
`;

export const User = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  width: 100%;
  max-width: 320px;
`;

export const Avatar = styled.button`
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
  flex-shrink: 0;
  border: 0;
  background: transparent;

  img,
  span > span {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    box-shadow: 0 0 0 1px rgba(240, 246, 252, 0.1);
    padding: 3px;
    border-radius: 50%;

    cursor: pointer;
  }

  &:hover {
    ${({ theme }) => css`
      background: ${shade(0.2, theme.colors.ice)};
    `}

    border-radius: 50%;
  }
`;

export const Theme = styled.div`
  width: 2.625rem;
  height: 1.5rem;
  border-radius: 1.5rem;

  ${({ theme }) => css`
    border: 3px solid
      ${theme.title === 'dark' ? theme.colors.ice : theme.colors.darkBlue};
  `}

  div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    top: -0.313rem;
    left: -0.25rem;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;

    ${({ theme }) => css`
      background: ${theme.title === 'dark'
        ? theme.colors.ice
        : theme.colors.darkBlue};
    `}

    transition: transform 0.3s cubic-bezier(0.4, 0.03, 0, 1);
    cursor: pointer;

    transform: ${({ theme }) =>
      theme.title === 'dark' ? 'translateX(-0.1rem)' : 'translateX(1rem)'};

    svg {
      ${({ theme }) => css`
        color: ${theme.title === 'dark'
          ? theme.colors.darkBlue
          : theme.colors.ice};
      `}

      cursor: pointer;

      :hover {
        opacity: 0.8;
      }
    }
  }
`;
