import styled, { css } from 'styled-components';

export const Section = styled.div`
  strong {
    color: ${props =>
      props.theme.title === 'dark'
        ? props.theme.colors.ice
        : props.theme.colors.darkBlue};
    font-weight: 600;
    font-size: 0.875rem;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto 10px;
  display: flex;

  @media screen and (max-width: 590px) {
    width: 100%;
  }
`;

export const Blocked = styled.div`
  ${({ theme }) => css`
    width: 56%;

    & .e-listbox-tool {
      border: none;
      border-radius: 10px;
      background: ${theme.colors.ice};
      margin-right: 15px;

      button {
        background: ${theme.colors.cyan};
        color: ${theme.colors.ice};
        border-radius: 5px;

        padding: 0.5rem;

        &:hover {
          filter: brightness(1.2);
        }
      }
    }

    & .e-listbox-wrapper {
      border: none;
      border-radius: 10px;
      background: ${theme.colors.ice};
    }

    & .e-listboxtool-wrapper {
      height: 100% !important;
      max-height: 100% !important;
      overflow: auto !important;
    }

    & .e-listbox-wrapper .e-list-item.e-selected {
      color: ${theme.colors.darkBlue};
    }

    & .e-list-item {
      font-family: 'Poppins', sans-serif;
      background: ${theme.colors.ice};
      color: ${theme.colors.lightGray};

      &:hover {
        filter: brightness(0.9);
        color: red;
      }
    }

    h4 {
      font-size: 0.9rem;
    }

    @media screen and (max-width: 400px) {
      width: 60%;
    }
  `}
`;

export const Released = styled.div`
  ${({ theme }) => css`
    width: 44%;

    & .e-listbox-wrapper {
      border: none;
      border-radius: 10px;
      background: ${theme.colors.ice};

      height: 100% !important;
      max-height: 100% !important;
      overflow: auto !important;
    }

    & .e-list-item {
      font-family: 'Poppins', sans-serif;
      background: ${theme.colors.ice};
      color: ${theme.colors.lightGray};

      &:hover {
        filter: brightness(0.9);
      }
    }

    & .e-listbox-wrapper .e-list-item.e-selected {
      color: ${theme.colors.darkBlue};
    }

    h4 {
      font-size: 0.9rem;
    }

    @media screen and (max-width: 400px) {
      width: 40%;
    }
  `}
`;
