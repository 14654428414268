import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  font-size: 0.875rem;

  .breadcrumb-active {
    ${({ theme }) => css`
      color: ${theme.colors.cyan};
    `}
  }

  a {
    &:hover:first-child {
      filter: brightness(1.3);
    }
  }

  svg {
    margin: 0px 0.1rem;
  }
`;
