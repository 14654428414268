import { useEffect, useState } from 'react';
import { Column } from '@material-table/core';

import { api } from 'services/api';

import { ITableActions, IFormAction } from 'models/ITableActions';

import Table from 'components/Table';
import { NewModal } from 'components/Modal';
import { Breadcrumbs } from 'components/Breadcrumbs';

import CrudModule from './Crud';

type RowData = Column<RowData[]>;

export default function Module() {
  const [isNewCompanyModalOpen, setIsNewCompanyModalOpen] = useState(false);
  const [moduleData, setModuleData] = useState<RowData[]>([]);
  const [typeOfModuleAction, setTypeOfModuleAction] = useState<IFormAction>({
    typeOfAction: 'view',
  });
  const [columns, setColumns] = useState<Column<RowData>[]>([]);

  function handleOpenNewCompanyModal() {
    setIsNewCompanyModalOpen(true);
  }

  function handleCloseNewCompanyModal() {
    setTypeOfModuleAction({ id: '', typeOfAction: 'view' });
    setIsNewCompanyModalOpen(false);
  }

  async function createModule() {
    setTypeOfModuleAction({ typeOfAction: 'include' });
    handleOpenNewCompanyModal();
  }

  async function updateModule(dataUpdate: any) {
    setTypeOfModuleAction({
      id: dataUpdate.id,
      typeOfAction: 'update',
    });
    handleOpenNewCompanyModal();
  }

  async function deleteModule(dataDelete: any) {
    setTypeOfModuleAction({
      id: dataDelete.id,
      typeOfAction: 'delete',
    });
    handleOpenNewCompanyModal();
  }

  useEffect(() => {
    async function getCompany() {
      await api
        .get('module')
        .then(response => {
          setModuleData(response.data);
        })
        .catch(() => {
          setModuleData([]);
        });

      const columnsData: Column<RowData>[] = [
        { title: 'MÓDULO', field: 'nome' },
        { title: 'ÍCONE', field: 'icone' },
      ];

      setColumns(columnsData);
    }
    !isNewCompanyModalOpen && getCompany();
  }, [isNewCompanyModalOpen]);

  const tableActions: ITableActions = {
    create: createModule,
    update: updateModule,
    delete: deleteModule,
  };

  return (
    <>
      <Breadcrumbs />
      <Table
        componentData={moduleData}
        columnsData={columns}
        tableActions={tableActions}
      />
      <NewModal
        isOpen={isNewCompanyModalOpen}
        onRequestClose={handleCloseNewCompanyModal}
      >
        <CrudModule
          action={typeOfModuleAction}
          onRequestClose={handleCloseNewCompanyModal}
        />
      </NewModal>
    </>
  );
}
