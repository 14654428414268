import { ReactNode } from 'react';

import { useCan } from 'hooks/can';

import { NoPermission } from 'pages/NoPermission';

import { RolesValidation } from 'models/IRoles';
import { useAuth } from 'hooks/auth';

interface ICanProps {
  children: ReactNode;
  roles?: RolesValidation;
  isAnAccessViaRouter?: boolean;
}

export function Can({
  children,
  roles,
  isAnAccessViaRouter = false,
}: ICanProps) {
  const { isAuthenticated } = useAuth();

  const userCanSeeComponent = useCan({
    roles,
  });

  if (!userCanSeeComponent && isAnAccessViaRouter) {
    if (!isAuthenticated) return null;
    return <NoPermission />;
  }

  if (!userCanSeeComponent) {
    return null;
  }

  return <>{children}</>;
}
