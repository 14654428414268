import {
  FaIndustry,
  FaUserCog,
  FaElementor,
  FaRegWindowMaximize,
  FaUsersCog,
} from 'react-icons/fa';

import { useTheme } from 'hooks/theme';

import IConfigurationItem from 'models/IConfigurationItem';

export const ConfigurationsData = () => {
  const { theme } = useTheme();

  const configurationsData: IConfigurationItem[] = [
    {
      title: 'Empresa',
      titleColor: theme.colors.cyan,
      description: 'Manutenção geral no cadastro das empresas.',
      path: '/configurations/company',
      icon: FaIndustry,
      iconColor: theme.colors.blueGradient,
      rolePermissions: ['Administrador'],
    },
    {
      title: 'Usuário',
      titleColor: theme.colors.darkBlueGradient,
      description: 'Manutenção geral no cadastro dos usuários.',
      path: '/configurations/user',
      icon: FaUsersCog,
      iconColor: theme.colors.darkBlueGradient,
      rolePermissions: ['Administrador', 'Moderador'],
    },
    {
      title: 'Grupo de Acesso',
      titleColor: theme.colors.lightRed,
      description: 'Manutenção geral no cadastro dos grupos de acesso.',
      path: '/configurations/access-group',
      icon: FaUserCog,
      iconColor: theme.colors.redGradient,
      rolePermissions: ['Administrador', 'Moderador'],
    },
    {
      title: 'Módulo',
      titleColor: theme.colors.yellow,
      description: 'Manutenção geral dos módulos do sistema.',
      path: '/configurations/module',
      icon: FaElementor,
      iconColor: theme.colors.yellowGradient,
      rolePermissions: ['Administrador'],
    },
    {
      title: 'Aplicação',
      titleColor: theme.colors.lightGreen,
      description: 'Manutenção geral das aplicações do sistema.',
      path: '/configurations/application',
      icon: FaRegWindowMaximize,
      iconColor: theme.colors.greenGradient,
      rolePermissions: ['Administrador'],
    },
  ];

  return configurationsData;
};
