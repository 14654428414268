import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal';

const appearFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateX(55px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const ModalContainer = styled(Modal)`
  position: relative;
  animation: ${appearFromTop} 0.3s ease-in-out;
  height: auto;

  max-height: 100%;
  overflow: auto;

  background: ${props => props.theme.colors.backgroundBox};

  border-radius: 5px;
  padding: 2rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 30px;
  -webkit-overflow-scrolling: touch;

  h2 {
    color: ${props =>
      props.theme.title === 'dark'
        ? props.theme.colors.ice
        : props.theme.colors.darkBlue};
    font-size: clamp(0.6rem, 0.8rem + 1vw, 1.5rem);

    margin-bottom: 2rem;
    margin-top: -1rem;

    @media screen and (max-width: 768px) {
      margin-top: 0rem;
    }
  }

  .react-modal-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: 0;
    background: transparent;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.5);
    }

    svg {
      font-size: 1rem;
      color: ${props =>
        props.theme.title === 'dark'
          ? props.theme.colors.ice
          : props.theme.colors.darkBlue};
    }

    @media screen and (max-width: 468px) {
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 1rem;
  }

  @media screen and (max-width: 468px) {
    width: 100%;
    padding: 1rem;
  }
`;

export const Content = styled.div`
  height: 100%;
`;
