/* eslint-disable @typescript-eslint/no-unused-vars */
import Skeleton from 'react-loading-skeleton';
import { animated, useSpring } from 'react-spring';

import { ICard } from 'models/ICard';

import { formatValue } from 'utils/formatValue';
import { Container, Content, Header } from './styles';

export function Card({
  id,
  value,
  title,
  tag,
  description,
  backgroundColor,
  valueColor,
  descriptionColor,
  headerTextColor,
  headerTagColor,
  headerTagBackground,
  containsAnimation = false,
  isValueFormatted = false,
}: ICard) {
  const { valueAnimation } = useSpring({
    valueAnimation: containsAnimation ? value : 0,
    delay: 500,
  });

  return (
    <Container backgroundColor={backgroundColor} key={id}>
      {value !== undefined ? (
        <>
          <Header
            headerTextColor={headerTextColor}
            headerTagColor={headerTagColor}
            headerTagBackground={headerTagBackground}
          >
            <p>{title}</p>

            {tag && (
              <div>
                <span>{tag}</span>
              </div>
            )}
          </Header>
          <Content valueColor={valueColor} descriptionColor={descriptionColor}>
            <strong>{isValueFormatted ? value : formatValue(value)}</strong>
            <span>{description}</span>
          </Content>
        </>
      ) : (
        <Skeleton height="5.6rem" />
      )}
    </Container>
  );
}
