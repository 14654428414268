import { useCallback, useRef, useState } from 'react';
import { FiMail, FiUser, FiLock, FiCamera, FiArrowLeft } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { api } from 'services/api';

import { useAuth } from 'hooks/auth';
import { useTheme } from 'hooks/theme';

import Button from 'components/Button';
import Input from 'components/Input';
import { UserAvatar } from 'components/Avatar';
import { UploadAvatar } from 'components/ImageUpload';

import getValidationErrors from 'utils/getValidationErrors';
import { regExpPassword } from 'utils/regExp';

import { Container, Content, AvatarInput } from './styles';

interface ProfileFormData {
  nome: string;
  email: string;
  senha_antiga: string;
  senha: string;
  confirmacao_senha: string;
}

export default function Profile() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { user, updateUser, handleUserRender } = useAuth();
  const { theme } = useTheme();

  const { colors } = theme;

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);

  function handleOpenNewModal() {
    setIsNewModalOpen(true);
  }

  function handleCloseNewModal() {
    setIsNewModalOpen(false);
    handleUserRender();
  }

  const handleSubmit = useCallback(async (data: ProfileFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('E-mail obrigatório!')
          .email('Digite um e-mail válido!'),
        senha_antiga: Yup.string(),
        senha: Yup.string().when('senha_antiga', {
          is: (val: string) => !!val.length,
          then: Yup.string()
            .required('Campo obrigatório!')
            .notOneOf(
              [Yup.ref('senha_antiga')],
              'Senha deve ser diferente da anterior!',
            )
            .matches(
              regExpPassword(),
              'Deve conter 8 caracteres, com pelo menos 1 carácter especial, 1 maiúscula, 1 número e minúscula!',
            ),
          otherwise: Yup.string(),
        }),
        confirmacao_senha: Yup.string()
          .when('senha_antiga', {
            is: (val: string) => !!val.length,
            then: Yup.string().required('Campo obrigatório'),
            otherwise: Yup.string(),
          })
          .oneOf([Yup.ref('senha')], 'Confirmação incorreta'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { nome, email, senha_antiga, senha, confirmacao_senha } = data;

      const formData = {
        nome,
        email,
        ...(senha_antiga
          ? {
              senha_antiga,
              senha,
              confirmacao_senha,
            }
          : {}),
      };

      const response = await api.put('/profile', formData);

      updateUser(response.data);

      history.push('/');

      toast.success(
        'Suas informações do perfil foram atualizadas com sucesso!',
        { delay: 400 },
      );
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        toast.error('Ocorreu um erro ao atualizar perfil, tente novamente.', {
          delay: 400,
        });
      }
    }
  }, []);

  return (
    <>
      <Container>
        <header>
          <Link to="/">
            <FiArrowLeft />
          </Link>
        </header>
        <Content>
          <Form
            ref={formRef}
            initialData={{
              nome: user?.nome,
              email: user?.email,
            }}
            onSubmit={handleSubmit}
          >
            <AvatarInput>
              <UserAvatar />

              <button type="button" onClick={() => handleOpenNewModal()}>
                <FiCamera />
              </button>
            </AvatarInput>

            <h1>Meu perfil</h1>

            <section>
              <Input name="nome" icon={FiUser} placeholder="Nome" />
              <Input name="email" icon={FiMail} placeholder="E-mail" />

              <Input
                containerStyle={{ marginTop: 24 }}
                name="senha_antiga"
                icon={FiLock}
                type="password"
                placeholder="Senha atual"
                autoComplete="off"
              />

              <Input
                name="senha"
                icon={FiLock}
                type="password"
                placeholder="Nova senha"
                autoComplete="off"
              />

              <Input
                name="confirmacao_senha"
                icon={FiLock}
                type="password"
                placeholder="Confirmar senha"
                autoComplete="off"
              />
            </section>

            <Button type="submit" color={colors.blueGradient}>
              Confirmar mudanças
            </Button>
          </Form>
        </Content>
      </Container>
      <UploadAvatar
        isOpen={isNewModalOpen}
        onRequestClose={handleCloseNewModal}
        fieldName="avatar"
        name={user?.nome}
        apiUrl="users/avatar"
        imageUrl={user?.avatar_url}
      />
    </>
  );
}
