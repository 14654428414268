/* eslint-disable @typescript-eslint/no-unused-vars */
import Skeleton from 'react-loading-skeleton';
import { animated, useSpring, config } from 'react-spring';

import { IFullCard } from 'models/IFullCard';

import { formatValue } from 'utils/formatValue';
import {
  Container,
  Content,
  Header,
  Footer,
  Description,
  Info,
} from './styles';

export function FullCard({
  id,
  value,
  title,
  tag,
  description,
  containsAnimation = false,
  descriptionValue,
  descriptionValueTag,
  infoTag,
  info,
  infoIcon: InfoIcon,
  color,
  isValueFormatted = false,
}: IFullCard) {
  const springValue = useSpring({
    from: { val: 0 },
    to: { val: value },
    config: config.default,
  });

  const springDescription = useSpring({
    from: { val: 0 },
    to: { val: descriptionValue },
    config: config.default,
  });

  return (
    <Container key={id}>
      {value !== undefined ? (
        <>
          <Header color={color}>
            <p>{title}</p>

            {tag && (
              <div>
                <span>{tag}</span>
              </div>
            )}
          </Header>
          <Content color={color}>
            <strong>
              {isValueFormatted ? (
                value
              ) : (
                <animated.div>
                  {springValue.val.to(val => formatValue(val))}
                </animated.div>
              )}
            </strong>
          </Content>
          <Footer color={color}>
            <Description color={color}>
              <span>{description}</span>
              {descriptionValue && (
                <span>
                  {isValueFormatted ? (
                    descriptionValue
                  ) : (
                    <animated.div>
                      {springDescription.val.to(val =>
                        formatValue(Math.floor(val)),
                      )}
                    </animated.div>
                  )}
                  <i>{descriptionValueTag}</i>
                </span>
              )}
            </Description>
            <Info color={color}>
              {InfoIcon && <InfoIcon />}
              <i>
                {info}
                {infoTag}
              </i>
            </Info>
          </Footer>
        </>
      ) : (
        <Skeleton height="5.6rem" />
      )}
    </Container>
  );
}
