import { useEffect, useRef } from 'react';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { useField } from '@unform/core';

import { IDualListData } from 'models/IDualListData';

import { Section, Wrapper, Blocked, Released } from './styles';

const fields = { text: 'nome' };

const toolbar = {
  items: ['moveTo', 'moveFrom', 'moveAllTo', 'moveAllFrom'],
};

export default function DualListBox({
  blocked,
  released,
  title,
  name,
  ...rest
}: IDualListData) {
  const dualListRef = useRef(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: dualListRef,
      getValue: ref => ref.current.sortedData,
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField, rest]);

  return (
    <Section>
      <strong>{title}</strong>
      <Wrapper>
        <Blocked>
          <h4>Bloqueados</h4>
          <ListBoxComponent
            name={name}
            dataSource={blocked}
            fields={fields}
            scope="#combined-listbox"
            toolbarSettings={toolbar}
            ref={dualListRef}
          />
        </Blocked>
        <div />
        <Released>
          <h4>Liberados</h4>
          <ListBoxComponent
            name={name}
            dataSource={released}
            fields={fields}
            id="combined-listbox"
            ref={dualListRef}
          />
        </Released>
      </Wrapper>
    </Section>
  );
}
