import IConfigurationItem from 'models/IConfigurationItem';

import { Container, IconContainer, Content } from './styles';

export function CardConfig({
  title,
  titleColor,
  path,
  description,
  icon: Icon,
  iconColor,
}: IConfigurationItem) {
  return (
    <Container to={path}>
      <IconContainer iconColor={iconColor}>
        <Icon />
      </IconContainer>
      <Content titleColor={titleColor}>
        <strong>{title}</strong>
        <p>{description}</p>
      </Content>
    </Container>
  );
}
