import { Link, LinkProps, useLocation } from 'react-router-dom';
import { cloneElement, ReactElement, useEffect, useState } from 'react';

import { Container } from './styles';

interface IActiveLinkProps extends LinkProps {
  children: ReactElement;
  shouldMatchExactHref?: boolean;
}

export function ActiveLink({ children, ...rest }: IActiveLinkProps) {
  const { pathname } = useLocation();

  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (pathname === rest.href) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [pathname]);

  return (
    <Container active={isActive}>
      <Link {...rest}>{cloneElement(children)}</Link>
    </Container>
  );
}
