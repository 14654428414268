import { useEffect, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { useTheme } from 'hooks/theme';

import insightLightImg from 'assets/images/icone-insight-colored.svg';
import insightDarkImg from 'assets/images/icone-insight-dark.svg';

import { Menu } from './Menu';

import {
  SidebarNav,
  SidebarHeader,
  SidebarMenu,
  SidebarFooter,
} from './styles';

interface ISidebarProps {
  handleSidebar?: () => void;
  sidebar: boolean;
}

export default function Sidebar({ handleSidebar, sidebar }: ISidebarProps) {
  const { theme } = useTheme();

  const [widthSidebar, setWidthSidebar] = useState('');

  useEffect(() => {
    sidebar ? setWidthSidebar('255px') : setWidthSidebar('48px');
  }, [sidebar]);

  return (
    <SidebarNav widthSidebar={widthSidebar}>
      <SidebarHeader>
        <Link to="/home">
          <img
            src={theme.title === 'dark' ? insightDarkImg : insightLightImg}
            alt="logo"
            width={36}
            height={36}
          />
          {sidebar && <h1>insight</h1>}
        </Link>
      </SidebarHeader>
      <SidebarMenu>
        <Menu isSidebarExpanded={sidebar} />
      </SidebarMenu>
      <SidebarFooter>
        {sidebar ? (
          <FiChevronLeft onClick={handleSidebar} size={24} />
        ) : (
          <FiChevronRight onClick={handleSidebar} size={24} />
        )}
      </SidebarFooter>
    </SidebarNav>
  );
}
