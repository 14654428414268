import styled, { css } from 'styled-components';
import { ICardStyleColor } from 'models/ICard';

export const Container = styled.div<ICardStyleColor>`
  height: 100%;
  max-height: 100%;

  ${({ theme, backgroundColor }) => css`
    background: ${backgroundColor || theme.colors.blueGradient};
  `}

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;

  padding: 1rem;
`;

export const Header = styled.header<ICardStyleColor>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 0.7rem;

  ${({ theme, headerTextColor, headerTagColor, headerTagBackground }) => css`
    border-bottom: 1px solid ${headerTextColor || theme.colors.ice};

    p {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1rem;
      color: ${headerTextColor || theme.colors.ice};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 1.2rem;

      background: ${headerTagBackground || theme.colors.ice};
      border-radius: 3px;

      span {
        font-size: 0.8rem;
        font-weight: 500;
        color: ${headerTagColor || theme.colors.cyan};
      }
    }
  `}
`;

export const Content = styled.div<ICardStyleColor>`
  display: flex;
  flex-direction: column;

  ${({ theme, valueColor, descriptionColor }) => css`
    strong {
      font-family: 'Rubik';
      font-size: 2.25rem;
      line-height: 4rem;
      color: ${valueColor || theme.colors.ice};
    }

    span {
      font-size: 0.75rem;
      font-weight: 100;

      color: ${descriptionColor || theme.colors.ice};
    }
  `}
`;
