import { ReactNode } from 'react';

import { Container } from './styles';

interface ITooltipProps {
  title: string;
  className?: string;
  children: ReactNode;
  color?: string;
  fontSize?: string;
  responsiveModel?: boolean;
}

const Tooltip = ({
  title,
  className = '',
  children,
  color,
  fontSize = '0.875rem',
  responsiveModel = true,
}: ITooltipProps) => (
  <Container
    className={className}
    color={color}
    fontSize={fontSize}
    responsiveModel={responsiveModel}
  >
    {children}
    <span>{title}</span>
  </Container>
);

export default Tooltip;
