import {
  sub,
  format,
  Duration,
  eachMonthOfInterval,
  addDays,
  addMonths,
} from 'date-fns';

export const subtractDate = (
  dateFinale: string | Date,
  duration: Duration,
): string => {
  const formattedDate = new Date(dateFinale);

  const initialDate = format(sub(formattedDate, duration), 'yyyy/MM/dd');

  return initialDate;
};

export const generateDateArrayByPeriod = (from: string, to: string) => {
  const [startYear, startMonth, startDay] = from.split('-');
  const [endYear, endMonth, endDay] = to.split('-');
  const dateArray = eachMonthOfInterval({
    start: new Date(
      parseInt(startYear, 10),
      parseInt(startMonth, 10) - 1,
      parseInt(startDay, 10),
    ),
    end: new Date(
      parseInt(endYear, 10),
      parseInt(endMonth, 10) - 1,
      parseInt(endDay, 10),
    ),
  });

  const dateFormated = dateArray.map(date => format(date, 'MM/yyyy'));

  return dateFormated;
};

export const lastDay = (year: number, month: number) => {
  const date = new Date(year, month - 1, 1);
  return format(addDays(addMonths(date, 1), -1), 'yyyy-MM-dd');
};
