import { useEffect, useRef, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';

import { api } from 'services/api';

import { useMenu } from 'hooks/menu';
import { useCompany } from 'hooks/company';
import { useTheme } from 'hooks/theme';

import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Select';

import getValidationErrors from 'utils/getValidationErrors';

import { IFormAction } from 'models/ITableActions';

import { Container, Content, ButtonContainer } from './styles';

interface IFormData {
  id?: string;
  nome: string;
  icone: string;
}

interface ComponentProps {
  action: IFormAction;
  onRequestClose: () => void;
}

interface SelectProps {
  value: string;
  label: string;
}

export default function CrudApplication({
  action,
  onRequestClose,
}: ComponentProps) {
  const { typeOfAction } = action;

  const { handleUpdatingDataRealTime } = useMenu();
  const { companyUser } = useCompany();
  const { theme } = useTheme();

  const { colors } = theme;

  const [optionsSelect, setOptionsSelect] = useState<SelectProps[]>([]);
  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit: SubmitHandler<IFormData> = async (data, { reset }) => {
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório'),
        caminho: Yup.string().required('Caminho obrigatório'),
        icone: Yup.string().required('Icone obrigatório'),
        modulo_id: Yup.string().required('Selecione um módulo'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      if (action.id) {
        await api.put(`application/${action?.id}`, data);
      } else {
        await api.post('application', data);
      }

      await handleUpdatingDataRealTime(companyUser.empresa_id);

      toast.success('Aplicação salva com sucesso!');

      reset();
      onRequestClose();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        toast.error(
          'Ocorreu um erro ao tentar gravar os dados, tente novamente!',
          { delay: 400 },
        );
      }

      setLoading(false);
    }
  };

  const handleDelete = async () => {
    await api.delete(`application/${action?.id}`);

    toast.success('Aplicação excluída com sucesso!');
    onRequestClose();
  };

  const verifyPath = async (e: string) => {
    const verify = e.substr(0, 1);

    if (verify !== '/') {
      formRef.current?.setFieldValue('caminho', `/${e}`);
    }
  };

  useEffect(() => {
    async function getIndexApplication() {
      await api
        .get(`/application/${action?.id}`)
        .then(response => {
          const { data } = response;

          formRef.current?.setData({
            nome: data.nome,
            icone: data.icone,
            caminho: data.caminho,
            modulo_id: {
              label: data.modulo.nome,
              value: data.modulo.id,
            },
          });
        })
        .catch(() => {
          toast.error('Erro ao carregar os dados');
        });
    }
    action?.id && getIndexApplication();
  }, [action]);

  useEffect(() => {
    async function getApplications() {
      await api
        .get('module')
        .then(response => {
          const applicationResponse = response.data.map((item: IFormData) => ({
            value: item.id,
            label: item.nome,
          }));

          setOptionsSelect(applicationResponse);
        })
        .catch(() => {
          toast.error('Erro ao carregar os dados');
        });
    }

    getApplications();
  }, []);

  const titleModal = useMemo(
    () =>
      !action?.id
        ? 'Cadastro de Aplicação'
        : action.typeOfAction === 'update'
        ? 'Alteração de Aplicação'
        : 'Exclusão de Aplicação',
    [action],
  );

  return (
    <Container>
      <h2>{titleModal}</h2>

      <Content ref={formRef} onSubmit={handleSubmit}>
        <section>
          <Select
            name="modulo_id"
            label="Módulo de acesso"
            options={optionsSelect}
            requiredField
            isDisabled={typeOfAction === 'delete' && true}
          />
          <Input
            name="nome"
            label="Nome"
            placeholder="Nome"
            requiredField
            disabled={typeOfAction === 'delete' && true}
          />
          <Input
            name="caminho"
            label="Caminho"
            placeholder="/caminho"
            onBlurCapture={event => verifyPath(event.target.value)}
            requiredField
            disabled={action?.typeOfAction === 'delete' && true}
          />
          <Input
            name="icone"
            label="Icone"
            placeholder="Icone"
            requiredField
            disabled={action?.typeOfAction === 'delete' && true}
          />
        </section>
        <ButtonContainer>
          {action?.typeOfAction === 'delete' && (
            <span>Deseja realmente excluir este módulo?</span>
          )}
          <div>
            <Button
              type="button"
              color={colors.redGradient}
              onClick={onRequestClose}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              color={colors.greenGradient}
              loading={loading}
              type={typeOfAction === 'delete' ? 'button' : 'submit'}
              onClick={typeOfAction === 'delete' ? handleDelete : undefined}
            >
              {typeOfAction === 'delete' ? 'Excluir' : 'Gravar'}
            </Button>
          </div>
        </ButtonContainer>
      </Content>
    </Container>
  );
}
