import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  overflow: hidden;

  ${({ theme }) => css`
    background: ${theme.title === 'dark'
      ? theme.colors.backgroundBox
      : theme.colors.blueGradient};
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;

  @media (max-width: 1024px) {
    max-width: 1024px;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromRight} 1s;

  img {
    width: 100%;

    @media (max-width: 468px) {
      width: 80%;
    }
  }

  form {
    margin: 80px 0;
    width: 100%;
    padding: 8px;
    text-align: center;

    h1 {
      margin-bottom: 24px;

      ${({ theme }) => css`
        color: ${theme.colors.ice};
      `}
    }
  }

  > a {
    ${({ theme }) => css`
      color: ${theme.title === 'light'
        ? theme.colors.darkBlue
        : theme.colors.lightRed};
    `}

    display: block;
    margin: 24px 0;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    background: ${theme.colors.ice};
  `}

  animation: ${appearFromLeft} 1s;

  img {
    width: 100%;
    padding: 16px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;
