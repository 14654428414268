import styled, { css } from 'styled-components';

interface ITooltipProps {
  color?: string;
  fontSize?: string;
  responsiveModel?: boolean;
}

export const Container = styled.div<ITooltipProps>`
  position: relative;

  span {
    z-index: 15;
    padding: 8px;
    border-radius: 4px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    text-align: center;

    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translate(-50%);

    width: 144px;

    ${({ theme, color, fontSize, responsiveModel }) => css`
      background: ${color || theme.colors.darkGray};
      color: ${theme.colors.ice};
      font-size: ${fontSize};

      ${responsiveModel &&
      css`
        @media (max-width: 768px) {
          left: 50%;
          transform: translate(-80%);
        }
      `}
    `}

    &::before {
      content: '';
      border-style: solid;
      border-color: ${({ theme, color }) => color || theme.colors.darkGray}
        transparent;
      border-width: 6px 6px 0 6px;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      ${({ responsiveModel }) => css`
        ${responsiveModel &&
        css`
          @media (max-width: 768px) {
            left: 80%;
            transform: translateX(-50%);
          }
        `}
      `}
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
