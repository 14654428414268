import { FiAlertCircle } from 'react-icons/fi';

import { useTheme } from 'hooks/theme';

import { NewModal } from 'components/Modal';
import Button from 'components/Button';

import { Container, IconWarning, Content, ButtonsContent } from './styles';

export interface IMessages {
  title: string;
  description?: string;
}

interface IWarningMessageProps {
  isNewWarningAction: boolean;
  handleCloseNewWarningAction: () => void;
  handleConfirmation: () => void;
  message: IMessages;
  loading: boolean;
}

export function WarningMessage({
  message,
  loading,
  handleCloseNewWarningAction,
  isNewWarningAction,
  handleConfirmation,
}: IWarningMessageProps) {
  const { theme } = useTheme();

  const { colors } = theme;

  return (
    <NewModal
      isOpen={isNewWarningAction}
      onRequestClose={handleCloseNewWarningAction}
    >
      <Container>
        <IconWarning>
          <FiAlertCircle />
        </IconWarning>
        <Content>
          <h3>{message.title}</h3>
          {message.description && <p>{message.description}</p>}
        </Content>
        <ButtonsContent>
          <Button
            color={colors.redGradient}
            size="3rem"
            onClick={handleCloseNewWarningAction}
            loading={loading}
          >
            Não
          </Button>
          <Button
            color={colors.greenGradient}
            size="3rem"
            onClick={handleConfirmation}
            loading={loading}
          >
            Sim
          </Button>
        </ButtonsContent>
      </Container>
    </NewModal>
  );
}
