import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface IContainerProps {
  isFocused: boolean;
  isErrored: boolean;
  isFilled: boolean;
  inputSize?: string;
}

export const Container = styled.div`
  label {
    margin-left: 0.2rem;

    ${({ theme }) => css`
      color: ${theme.title === 'dark'
        ? theme.colors.ice
        : theme.colors.darkBlue};
      font-weight: 600;
      font-size: 0.875rem;

      strong {
        margin-right: 0.1rem;
        color: ${theme.colors.lightRed};
      }
    `}
  }
`;

export const InputContent = styled.div<IContainerProps>`
  ${({ theme, isErrored, isFilled, isFocused, inputSize }) => css`
    background: ${theme.colors.ice};
    border-radius: 10px;
    padding: ${inputSize || '0.5rem'};

    border: 2px solid ${theme.colors.ice};
    color: ${theme.colors.lightGray};

    display: flex;
    align-items: center;

    ${isErrored &&
    css`
      border-color: ${theme.colors.lightRed};
      color: ${theme.colors.lightRed};
    `}

    ${isFocused &&
    css`
      color: ${theme.colors.lightGreen};
      border-color: ${theme.colors.lightGreen};
    `}


    ${isFilled &&
    css`
      color: ${theme.colors.lightGreen};
    `}


    input {
      flex: 1;
      width: 100%;
      background: transparent;
      border: 0;
      color: ${theme.colors.lightGray};
      font-weight: 500;
      font-size: 1rem;

      &::placeholder {
        color: ${theme.colors.lightGray};
        opacity: 0.3;
      }
    }

    .icon-input {
      margin-right: 0.5rem;
    }
  `}
`;

export const VisiblePassword = styled.div`
  display: flex;
  margin: 0;
  padding: 4px;

  svg {
    cursor: pointer;
  }
`;

export const Error = styled(Tooltip)`
  ${({ theme }) => css`
    height: 20px;

    svg {
      margin: 0;
    }

    span {
      background: ${theme.colors.lightRed};
      color: #fff;

      &::before {
        border-color: ${theme.colors.lightRed} transparent;
      }
    }
  `}
`;
