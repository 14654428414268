import styled, { css } from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Content = styled(Form)`
  ${({ theme }) => css`
    section {
      display: grid;
      grid-template-columns: repeat(3, minmax(240px, 1fr));
      gap: 1rem;

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        gap: 0.5rem;
      }

      @media (max-width: 468px) {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
      }
    }

    hr {
      margin: 1.5rem 0 1.5rem 0;
      border: 1px solid ${theme.colors.backgroundOverlay};
    }

    label {
      margin-left: 0.2rem;

      color: ${theme.title === 'dark'
        ? theme.colors.ice
        : theme.colors.darkBlue};
      font-weight: 600;
      font-size: 0.875rem;

      strong {
        margin-right: 0.1rem;
        color: ${theme.colors.lightRed};
      }
    }
  `}
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;

  span {
    color: red;
  }

  div {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 1rem;

    @media (max-width: 768px) {
      grid-auto-flow: row;
      gap: 0;
    }
  }
`;
