import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

interface IActiveLinkProps {
  active: boolean;
}

export const Container = styled.div<IActiveLinkProps>`
  ${({ theme, active }) => css`
    background: ${active
      ? transparentize(
          0.5,
          theme.title === 'dark' ? theme.colors.darkBlue : theme.colors.ice,
        )
      : ''};
    border-left: ${active ? `4px solid ${theme.colors.ice}` : 'none'};
  `}
`;
