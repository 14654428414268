import { ReactNode } from 'react';
import { FiX } from 'react-icons/fi';

import { ModalContainer, Content } from './styles';

interface INewModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  children: ReactNode;
}

export function SidebarModal({
  isOpen,
  onRequestClose,
  children,
}: INewModalProps) {
  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-sidebar-overlay"
      bodyOpenClassName="react-modal-sidebar-open"
      closeTimeoutMS={300}
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <FiX />
      </button>
      <Content>{children}</Content>
    </ModalContainer>
  );
}
