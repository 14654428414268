import React from 'react';

import { Container } from './styles';

const Footer: React.FC = () => {
  const anoAtual = new Date().getFullYear();

  return (
    <Container>
      <span>ConsulTI © {anoAtual}</span>
      <span>
        Insight <strong>Analytical</strong>
      </span>
    </Container>
  );
};
export default Footer;
