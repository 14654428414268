import { useRef, useCallback, useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { FiArrowLeft, FiLock } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useTheme } from 'hooks/theme';

import { api } from 'services/api';

import getValidationErrors from 'utils/getValidationErrors';
import { regExpPassword } from 'utils/regExp';

import Input from 'components/Input';
import Button from 'components/Button';

import logoInsight from 'assets/images/logo.svg';
import backgroundDark from 'assets/images/login-background-dark.svg';
import backgroundLight from 'assets/images/login-background-light.svg';

import {
  Container,
  Content,
  AnimationContainer,
  Section,
  Background,
} from './styles';

interface IResetPasswordFormData {
  senha: string;
  confirmacao_senha: string;
}

export default function ResetPassword() {
  const formRef = useRef<FormHandles>(null);

  const { theme } = useTheme();

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: IResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          senha: Yup.string()
            .required('Senha obrigatória')
            .matches(
              regExpPassword(),
              'Deve conter 8 caracteres, com pelo menos 1 carácter especial, 1 maiúscula, 1 número e minúscula',
            ),
          confirmacao_senha: Yup.string().oneOf(
            [Yup.ref('senha'), undefined],
            'Confirmação incorreta',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { senha, confirmacao_senha } = data;
        const token = location.search.replace('?token=', '');

        if (!token) {
          throw new Error();
        }

        setLoading(true);

        await api.post('/password/reset', {
          senha,
          confirmacao_senha,
          token,
        });

        toast.success('Senha alterada com sucesso!', {
          delay: 400,
        });

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          toast.error(
            'Ocorreu um erro ao resetar sua senha, tente novamente!',
            {
              delay: 400,
            },
          );
        }
        setLoading(false);
      }
    },
    [history, location.search],
  );

  return (
    <Container>
      <Background>
        <img
          src={theme.title === 'dark' ? backgroundDark : backgroundLight}
          alt="background-forgot-senha"
        />
      </Background>
      <Content>
        <AnimationContainer>
          <img src={logoInsight} alt="logo-insight" width={350} height={124} />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Resetar senha</h1>

            <Section>
              <Input
                name="senha"
                icon={FiLock}
                type="password"
                placeholder="Nova senha"
                inputSize="1rem"
              />

              <Input
                name="confirmacao_senha"
                icon={FiLock}
                type="password"
                placeholder="Confirmação da Senha"
                inputSize="1rem"
              />
            </Section>

            <Button type="submit" loading={loading}>
              Alterar senha
            </Button>
          </Form>
          <Link to="/">
            <FiArrowLeft />
            Voltar para login
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
}
