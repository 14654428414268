/* eslint-disable no-console */
import { useEffect, useRef, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FormHandles, SubmitHandler } from '@unform/core';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

import { api } from 'services/api';

import { useCompany } from 'hooks/company';
import { useTheme } from 'hooks/theme';

import Input from 'components/Input';
import Button from 'components/Button';
import DualListBox from 'components/DualListBox';
import Select from 'components/Select';

import getValidationErrors from 'utils/getValidationErrors';

import { IFormAction } from 'models/ITableActions';

import { useAuth } from 'hooks/auth';
import { Container, Content, ContentUser, ButtonContainer } from './styles';

interface IFormData {
  nome: string;
  cargo: IRoles[];
  email: string;
  senha?: string;
  empresa_id?: string;
  grupo: IFormAccessGroupData[];
}

interface IFormAccessGroupData {
  grupo_acesso_id: string;
  usuario_grupo_id?: string;
  nome: string;
}

interface IAccessGroup {
  [key: string]: Object;
  grupo_acesso_id: string;
  nome: string;
}

interface IUserAccessGroup {
  id: string;
  grupo_acesso: IAccessGroup;
}

interface IRoles {
  id: string;
  nome: string;
}

interface IUserRoles {
  id: string;
  cargo: IRoles;
}

interface ISelectProps {
  value: string;
  label: string;
}

interface IComponentProps {
  action: IFormAction;
  onRequestClose: () => void;
}

export default function CrudUser({ action, onRequestClose }: IComponentProps) {
  const { typeOfAction } = action;

  const { companyUser } = useCompany();
  const { theme } = useTheme();
  const { handleUserRender } = useAuth();

  const { colors } = theme;

  const [loading, setLoading] = useState(false);
  const [accessGroup, setAccessGroup] = useState<IAccessGroup[]>([]);
  const [userAccessGroup, setUserAccessGroup] = useState([]);
  const [rolesData, setRolesData] = useState<ISelectProps[]>([]);

  const formRef = useRef<FormHandles>(null);

  const handleFormatUserAccessGroup = (
    grupo: IFormAccessGroupData[],
    userId: string,
  ) =>
    grupo.map(itemGroup => ({
      grupo_acesso_id: itemGroup.grupo_acesso_id,
      usuario_id: userId,
    }));

  const handleFormatUserRole = (roles: IRoles[], userId: string) =>
    roles.map(roleId => ({
      cargo_id: roleId,
      usuario_id: userId,
    }));

  const handleSubmit: SubmitHandler<IFormData> = async (data, { reset }) => {
    try {
      setLoading(true);

      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('E-mail obrigatório')
          .email('E-mail inválido'),
        cargo: Yup.array().min(1).required('Cargo obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { nome, email, grupo, cargo } = data;

      if (action.id) {
        const formData = {
          nome,
          email,
        };

        await api.put(`users/${action.id}`, formData);

        cargo.length > 0 && (await api.delete(`user-role/all/${action.id}`));

        const formattedRoles = handleFormatUserRole(cargo, action.id);

        await api.post('user-role', formattedRoles);

        userAccessGroup.length > 0 &&
          (await api.delete(
            `user-access-group/user/${companyUser.empresa_id}/${action.id}`,
          ));

        const grupoAcessoFormated = handleFormatUserAccessGroup(
          grupo,
          action.id,
        );

        await api.post('user-access-group', grupoAcessoFormated);

        handleUserRender();
      } else {
        const formData = {
          nome,
          email,
          senha: uuid(),
          empresa_id: companyUser.empresa_id,
        };

        const newUser = await api
          .post('users', formData)
          .then(response => response.data);

        const formattedRoles = handleFormatUserRole(cargo, newUser.id);

        await api.post('user-role', formattedRoles);

        const formattedAccessGroup = handleFormatUserAccessGroup(
          grupo,
          newUser.id,
        );

        await api.post('user-access-group', formattedAccessGroup);

        toast.info('Aguarde, estamos enviando um e-mail de ativação!');

        await api.post('/activation/', {
          email,
          empresa_id: companyUser.empresa_id,
        });

        toast.success(
          'Enviamos um e-mail de ativação para o(a) colaborador(a)!',
        );
      }

      reset();
      onRequestClose();
      toast.success('Usuário salvo com sucesso!');
    } catch (err) {
      setLoading(false);
      if (err.response) {
        toast.error(
          'Ocorreu um erro ao tentar gravar os dados, tente novamente!',
          { delay: 400 },
        );
      }

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  };

  const handleDelete = async () => {
    await api.delete(`users/${action?.id}`);

    toast.success('Usuário excluído com sucesso!');
    onRequestClose();
  };

  useEffect(() => {
    async function getIndexUser() {
      const [user, roles] = await Promise.all([
        await api.get(`/profile/${action?.id}`).then(response => response.data),
        await api
          .get<IUserRoles[]>(`/user-role/all/${action?.id}`)
          .then(response => response.data),
      ]);

      const selectData = roles.map(userRole => ({
        value: userRole.cargo.id,
        label: userRole.cargo.nome,
      }));

      formRef.current?.setData({
        nome: user.nome,
        email: user.email,
        cargo: selectData,
      });
    }
    action?.id && getIndexUser();
  }, [action]);

  useEffect(() => {
    async function getAccessGroup(): Promise<void> {
      const [accessGroupData, accessUserData] = await Promise.all([
        api
          .get(`access-group/one-company/${companyUser.empresa_id}`)
          .then(response => response.data)
          .catch(err => {
            console.log(err);
          }),
        action.id &&
          api
            .get(
              `user-access-group/user/${companyUser.empresa_id}/${action.id}`,
            )
            .then(response => response.data)
            .catch(err => {
              console.log(err);
            }),
      ]);

      const groupNotAccess: IAccessGroup[] = [];

      if (accessUserData) {
        accessGroupData.forEach((groupAccess: any) => {
          const verifyAccess = accessUserData.find(
            (userGroup: IUserAccessGroup) =>
              userGroup.grupo_acesso.id === groupAccess.id,
          );

          if (!verifyAccess)
            groupNotAccess.push({
              grupo_acesso_id: groupAccess.id,
              nome: groupAccess.nome,
            });
        });

        const refactorAccessUser = accessUserData.map(
          (item: IUserAccessGroup) => ({
            usuario_grupo_id: item.id,
            grupo_acesso_id: item.grupo_acesso.id,
            nome: item.grupo_acesso.nome,
          }),
        );

        setUserAccessGroup(refactorAccessUser);
        setAccessGroup(groupNotAccess);
      } else if (accessGroupData) {
        const refactorGroupAccess = accessGroupData.map(
          (item: IAccessGroup) => ({
            grupo_acesso_id: item.id,
            nome: item.nome,
          }),
        );

        setAccessGroup(refactorGroupAccess);
      }
    }
    getAccessGroup();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<IRoles[]>('/role');

        const selectData = response.data.map(role => ({
          value: role.id,
          label: role.nome,
        }));

        setRolesData(selectData);
      } catch {
        console.log('err');
      }
    })();
  }, []);

  const titleModal = useMemo(
    () =>
      !action?.id
        ? 'Cadastro de Usuário'
        : action.typeOfAction === 'update'
        ? 'Alteração de Usuário'
        : 'Exclusão de Usuário',
    [action],
  );

  return (
    <Container>
      <h2>{titleModal}</h2>
      <Content ref={formRef} onSubmit={handleSubmit}>
        <ContentUser>
          <Input
            name="nome"
            label="Nome"
            placeholder="Nome"
            requiredField
            disabled={typeOfAction === 'delete' && true}
          />
          <Input
            name="email"
            label="Email"
            placeholder="Email"
            requiredField
            disabled={typeOfAction === 'delete' && true}
          />
        </ContentUser>
        <Select
          name="cargo"
          label="Cargo"
          options={rolesData}
          requiredField
          isMulti
          isDisabled={typeOfAction === 'delete' && true}
        />
        <section className="duallist">
          <DualListBox
            title="Grupo de acesso"
            blocked={accessGroup}
            released={userAccessGroup}
            name="grupo"
          />
        </section>

        <ButtonContainer>
          {typeOfAction === 'delete' && (
            <span>Deseja realmente excluir este usuário?</span>
          )}

          <div>
            <Button
              type="button"
              color={colors.redGradient}
              onClick={onRequestClose}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              type={typeOfAction === 'delete' ? 'button' : 'submit'}
              color={colors.greenGradient}
              loading={loading}
              onClick={typeOfAction === 'delete' ? handleDelete : undefined}
            >
              {typeOfAction === 'delete' ? 'Excluir' : 'Gravar'}
            </Button>
          </div>
        </ButtonContainer>
      </Content>
    </Container>
  );
}
