import { useEffect, useState } from 'react';
import { Column } from '@material-table/core';
import { toast } from 'react-toastify';

import { useTheme } from 'hooks/theme';

import { api } from 'services/api';

import { ITableActions, IFormAction } from 'models/ITableActions';

import Table from 'components/Table';
import Tooltip from 'components/Tooltip';
import { NewModal } from 'components/Modal';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { WarningMessage, IMessages } from 'components/WarningMessage';

import CrudCompany from './Crud';
import LinkModule from './LinkModule';
import LicenceUpdate from './LicenceUpdate';

import { Status } from './styles';

type tableData = {
  id: string;
};

interface IRowData extends Column<IRowData[]> {
  tableData?: tableData;
  status?: boolean;
  quantidade_licenca?: number;
  quantidade_licenca_em_uso?: number;
}

export default function Company() {
  const { theme } = useTheme();

  const [isNewCompanyModalOpen, setIsNewCompanyModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState<IRowData[]>([]);
  const [typeOfCompanyAction, setTypeOfCompanyAction] = useState<IFormAction>({
    typeOfAction: 'view',
  });
  const [columns, setColumns] = useState<Column<IRowData>[]>([]);
  const [editModule, setEditModule] = useState(false);
  const [message, setMessage] = useState<IMessages>({
    title: '',
  });
  const [companyStatusUpdate, setCompanyStatusUpdate] = useState<IRowData>();
  const [isNewWarningAction, setIsNewWarningAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLicenceUpdateModalOpen, setIsLicenceUpdateModalOpen] =
    useState(false);
  const [currentLicenses, setCurrentLicenses] = useState({
    total: 0,
    inUse: 0,
    empresaId: '',
  });

  function handleOpenNewCompanyModal() {
    setIsNewCompanyModalOpen(true);
  }

  function handleOpenLicenceUpdateModal(
    licence: number | undefined,
    licenceInUse: number | undefined,
    empresaId: string | undefined,
  ) {
    setCurrentLicenses({
      total: licence || 0,
      inUse: licenceInUse || 0,
      empresaId: empresaId || '',
    });
    setIsLicenceUpdateModalOpen(true);
  }

  function handleCloseNewWarningAction() {
    if (!loading) setIsNewWarningAction(false);
  }

  function handleCloseNewCompanyModal() {
    setTypeOfCompanyAction({ id: '', typeOfAction: 'view' });
    setIsNewCompanyModalOpen(false);
    setEditModule(false);
  }

  function handleCloseLicenceUpdateModal() {
    setCurrentLicenses({
      inUse: 0,
      total: 0,
      empresaId: '',
    });
    setIsLicenceUpdateModalOpen(false);
  }

  function createCompany() {
    setTypeOfCompanyAction({ typeOfAction: 'include' });
    handleOpenNewCompanyModal();
  }

  function updateCompany(dataUpdate: any) {
    setTypeOfCompanyAction({
      id: dataUpdate.id,
      typeOfAction: 'update',
    });
    handleOpenNewCompanyModal();
  }

  function deleteCompany(dataDelete: any) {
    setTypeOfCompanyAction({
      id: dataDelete.id,
      typeOfAction: 'delete',
    });
    handleOpenNewCompanyModal();
  }

  function handleModuleCompany(data: any) {
    handleOpenNewCompanyModal();
    setTypeOfCompanyAction({
      id: data.id,
      nome: data.nome_fantasia,
      typeOfAction: 'custom',
    });
    setEditModule(true);
  }

  function handleStatusClick(dataCompany: IRowData | undefined) {
    if (dataCompany) {
      if (dataCompany.status)
        setMessage({
          title: `Deseja realmente desativar?`,
        });
      else
        setMessage({
          title: `Deseja realmente ativar?`,
        });

      setCompanyStatusUpdate(dataCompany);
      setIsNewWarningAction(true);
    }
  }

  async function handleUpdateStatus() {
    setLoading(true);

    if (companyStatusUpdate?.id) {
      await api
        .put(`company/status/${companyStatusUpdate.id}`)
        .then(() => {
          const companys = companyData.map(item =>
            item.id === companyStatusUpdate.id
              ? { ...item, status: !companyStatusUpdate.status }
              : item,
          );
          setCompanyData(companys);
        })
        .catch(() => {
          toast.error('Ocorreu um problema ao tentar efetuar a desativação.');
        });
    }
    setLoading(false);
    handleCloseNewWarningAction();
  }

  useEffect(() => {
    async function getCompany() {
      await api
        .get('company')
        .then(response => {
          setCompanyData(response.data);
        })
        .catch(() => {
          setCompanyData([]);
        });
    }
    !isNewCompanyModalOpen && !isLicenceUpdateModalOpen && getCompany();
  }, [isNewCompanyModalOpen, isLicenceUpdateModalOpen]);

  useEffect(() => {
    const columnsData: Column<IRowData>[] = [
      {
        title: 'EMPRESA',
        field: 'razao_social',
      },
      { title: 'CNPJ', field: 'cnpj' },
      {
        title: 'LICENÇAS',
        field: 'quantidade_licenca',
        type: 'numeric',
        align: 'center',
        render: (data: IRowData) => (
          <Tooltip
            title="Clique para alterar a quantidade"
            fontSize="0.625rem"
            responsiveModel={false}
          >
            <Status
              onClick={() =>
                handleOpenLicenceUpdateModal(
                  data.quantidade_licenca,
                  data.quantidade_licenca_em_uso,
                  data.tableData?.id,
                )
              }
              statusColor={theme.colors.lightGreen}
            >
              {data.quantidade_licenca}
            </Status>
          </Tooltip>
        ),
      },
      {
        title: 'LICENÇAS EM USO',
        field: 'quantidade_licenca_em_uso',
        type: 'numeric',
        align: 'center',
      },
      {
        title: 'STATUS',
        field: 'status',
        align: 'center',
        render: (data: IRowData) =>
          data.status ? (
            <Tooltip
              title="Clique para desativar a empresa"
              fontSize="0.625rem"
              responsiveModel={false}
            >
              <Status
                onClick={() => handleStatusClick(data)}
                statusColor={theme.colors.lightGreen}
              >
                ATIVO
              </Status>
            </Tooltip>
          ) : (
            <Tooltip
              title="Clique para ativar a empresa"
              fontSize="0.625rem"
              responsiveModel={false}
            >
              <Status
                onClick={() => handleStatusClick(data)}
                statusColor={theme.colors.lightRed}
              >
                INATIVO
              </Status>
            </Tooltip>
          ),
      },
    ];
    setColumns(columnsData);
  }, []);

  const tableActions: ITableActions = {
    create: createCompany,
    update: updateCompany,
    delete: deleteCompany,
    custom: handleModuleCompany,
  };

  return (
    <>
      <Breadcrumbs />
      <Table
        componentData={companyData}
        columnsData={columns}
        tableActions={tableActions}
        nameActionCustom="Editar Módulos"
      />
      <NewModal
        isOpen={isNewCompanyModalOpen}
        onRequestClose={handleCloseNewCompanyModal}
      >
        {editModule ? (
          <LinkModule
            action={typeOfCompanyAction}
            onRequestClose={handleCloseNewCompanyModal}
          />
        ) : (
          <CrudCompany
            action={typeOfCompanyAction}
            onRequestClose={handleCloseNewCompanyModal}
          />
        )}
      </NewModal>
      <NewModal
        isOpen={isLicenceUpdateModalOpen}
        onRequestClose={handleCloseLicenceUpdateModal}
      >
        <LicenceUpdate
          licenses={currentLicenses}
          onRequestClose={handleCloseLicenceUpdateModal}
        />
      </NewModal>
      <WarningMessage
        message={message}
        handleCloseNewWarningAction={handleCloseNewWarningAction}
        isNewWarningAction={isNewWarningAction}
        handleConfirmation={handleUpdateStatus}
        loading={loading}
      />
    </>
  );
}
