import { ReactNode, useState } from 'react';

import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

import { Container, Aside, Main } from './styles';

interface IPropsLayout {
  children?: ReactNode;
}

const Layout = ({ children }: IPropsLayout) => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  function handleSidebar() {
    setIsSidebarExpanded(!isSidebarExpanded);
  }

  return (
    <Container sidebarExpanded={isSidebarExpanded}>
      <Header />
      <Aside>
        <Sidebar sidebar={isSidebarExpanded} handleSidebar={handleSidebar} />
      </Aside>
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
};

export default Layout;
