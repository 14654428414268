import { createGlobalStyle, css } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  :root {
    ${({ theme }) => css`
      scrollbar-width: thin;
      scrollbar-color: ${theme.colors.darkBlue} ${theme.colors.ice};
    `}
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 768px) {
      font-size: 87.5%;
    }

    @media (max-width: 480px) {
      font-size: 75%;
    }
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ${({ theme }) => css`
    body {
      background-color: ${theme.colors.background};
      color: ${theme.colors.text};
      -webkit-font-smoothing: antialiased;

      a {
        text-decoration: none;
        color: inherit;
      }

      /* Works on Chrome, Edge, and Safari */
      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-track {
        border-radius: 6px;
        background: ${theme.colors.background};
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: ${theme.colors.darkBlue};
        border: 3px solid ${theme.colors.darkBlue};
      }
    }

    body,
    input,
    button {
      font: ${theme.font.normal} ${theme.font.sizes.medium}
        ${theme.font.family.poppins};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      font-weight: 500;
    }

    button {
      cursor: pointer;
    }

    .Toastify__toast--success {
      background: ${theme.colors.lightGreen};
    }

    .Toastify__toast--error {
      background: ${theme.colors.lightRed};
    }

    .Toastify__toast--info {
      background: ${theme.colors.cyan};
    }

    .Toastify__toast--warning {
      background: ${theme.colors.yellow};
    }

    .react-modal-sidebar-overlay {
      background: rgba(0, 0, 0, 0.5);

      position: fixed;

      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 250%;
      height: 100vh;

      padding: 1rem;
      z-index: 999;

      @media screen and (min-width: 769px) {
        opacity: 0;
        visibility: hidden;
      }
    }

    .react-modal-sidebar-open {
      touch-action: none;
      overflow: hidden;

      .ReactModal__Content--after-open {
        background: transparent;
      }

      .ReactModal__Overlay--before-close {
        opacity: 0;
        transform: translateX(-255px);
      }
    }

    .react-modal-filter-open {
      touch-action: none;
      width: calc(100% - 6px);
      overflow: hidden;

      @media only screen and (max-device-width: 1440px) {
        width: calc(100% - 0px);
      }

      .ReactModal__Overlay--before-close {
        opacity: 0;
        transform: translateX(255px);
      }
    }

    .react-modal-filter-overlay {
      background: rgba(0, 0, 0, 0.5);

      position: fixed;

      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      height: 100vh;

      padding: 1rem;
      z-index: 2;
    }

    .react-modal-menu-user-overlay {
      position: fixed;
      inset: 0px;
      background: rgba(0, 0, 0, 0.5);
      z-index: 10;
    }

    .react-modal-menu-user-open {
      touch-action: none;
      width: calc(100% - 6px);
      overflow: hidden;

      @media only screen and (max-device-width: 1440px) {
        width: calc(100% - 0px);
      }

      .ReactModal__Overlay--before-close {
        opacity: 0;
        transform: translateX(255px);
      }
    }

    .ReactModal__Overlay--after-open {
      opacity: 1;
      transform: translateX(0px);
      transition: all 0.3s ease-in-out;
    }

    .react-loading-skeleton {
      background-color: ${theme.colors.darkBlue} !important;
      background-image: linear-gradient(
        90deg,
        #38446e,
        #e9efff,
        #38446e
      ) !important;
      opacity: 0.1;
    }
  `}
`;
