import { shade } from 'polished';
import styled, { css, keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.backgroundBox};

    animation: ${appearFromLeft} 1s;

    width: 100%;
    min-height: 100vh;

    > header {
      height: 144px;
      background: ${theme.colors.blueGradient};
      display: flex;
      align-items: center;

      a {
        z-index: 1;
        margin: 0 auto 0 48px;

        svg {
          color: ${theme.colors.ice};
          width: 1.5rem;
          height: 1.5rem;

          &:hover {
            color: ${shade(0.2, theme.colors.ice)};
          }
        }
      }
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -176px auto 0;

  width: 100%;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;

    section {
      display: grid;
      gap: 0.5rem;
    }

    h1 {
      margin-bottom: 24px;
      font-size: 1.5rem;
      text-align: center;

      ${({ theme }) => css`
        color: ${theme.title === 'dark'
          ? theme.colors.ice
          : theme.colors.darkBlue};
      `}
    }

    @media screen and (max-width: 468px) {
      width: 80%;
    }
  }
`;

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;

  ${({ theme }) => css`
    img {
      width: 11.625rem;
      height: 11.625rem;
      border-radius: 50%;
      border: 2px solid
        ${theme.title === 'dark' ? theme.colors.ice : theme.colors.darkBlue};
    }

    button {
      position: absolute;
      width: 3rem;
      height: 3rem;
      background: ${theme.title === 'dark'
        ? theme.colors.ice
        : theme.colors.cyan};
      border-radius: 50%;
      right: 0;
      bottom: 20px;
      border: 0;
      transition: background-color 0.2s;

      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 1.25rem;
        height: 1.25rem;
        color: ${theme.title === 'dark'
          ? theme.colors.darkBlue
          : theme.colors.ice};
      }

      &:hover {
        background: ${shade(
          0.2,
          theme.title === 'dark' ? theme.colors.ice : theme.colors.cyan,
        )};
      }
    }
  `}
`;
