import { useContext, createContext, useState, ReactNode } from 'react';

import { DefaultTheme, ThemeProvider } from 'styled-components';
import { combineTheme, dark, light } from 'styles/themes';

interface IPropsChildren {
  children: ReactNode;
}

interface IThemeContextData {
  theme: DefaultTheme;
  ToggleTheme(): void;
}

const ThemeContext = createContext({} as IThemeContextData);

export const ThemesProvider = ({ children }: IPropsChildren) => {
  const [theme, setTheme] = useState<DefaultTheme>(() => {
    const themeLocal = localStorage.getItem('@Insight:theme');

    if (themeLocal) {
      return themeLocal === 'dark' ? combineTheme(dark) : combineTheme(light);
    }

    return combineTheme(light);
  });

  const ToggleTheme = () => {
    if (theme.title === 'dark') {
      localStorage.setItem('@Insight:theme', light.title);
      setTheme(combineTheme(light));
    } else {
      localStorage.setItem('@Insight:theme', dark.title);
      setTheme(combineTheme(dark));
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, ToggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export function useTheme(): IThemeContextData {
  const context = useContext(ThemeContext);

  return context;
}
