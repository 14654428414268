import { BrowserRouter as Router } from 'react-router-dom';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';

import AppProvider from './hooks';

import Routes from './start/Routes';

import GlobalStyle from './styles/global';

Modal.setAppElement('#root');

export function App() {
  return (
    <Router>
      <AppProvider>
        <Routes />
        <GlobalStyle />
        <ToastContainer autoClose={5000} limit={5} />
      </AppProvider>
    </Router>
  );
}
