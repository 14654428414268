import { Route, Switch } from 'react-router-dom';

import routesTemplates from 'routes';
import { NotFound } from 'pages/NotFound';
import AuthRouter from './authRoutes';

export default function Routes() {
  return (
    <Switch>
      {routesTemplates.map(routesTemplate => {
        const { routes: appRoutes, template: Template, type } = routesTemplate;

        return appRoutes.map(appRoute => (
          <AuthRouter
            appRoute={appRoute}
            type={type}
            path={appRoute.path}
            template={Template}
            exact={appRoute.exact}
          />
        ));
      })}
      <Route render={() => <NotFound />} />
    </Switch>
  );
}
