import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100%;
  flex-direction: column;

  img {
    margin-top: 48px;
    width: 100%;
    max-width: 36rem;
  }
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 24px;

  h1 {
    text-align: center;
    font-weight: bold;
    color: ${props => props.theme.colors.darkBlue};
  }

  div {
    display: flex;
    text-align: center;
    flex-direction: column;

    strong {
      color: ${props => props.theme.colors.darkGray};
    }

    a {
      color: ${props => props.theme.colors.cyan};
    }
  }

  ${({ theme }) => css`
    color: ${theme.title === 'dark' ? theme.colors.ice : theme.colors.darkBlue};
  `};
`;
