import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import * as Icons from 'react-icons/fa';
import { IconType } from 'react-icons/lib/esm';

import { useMenu } from 'hooks/menu';
import { useCompany } from 'hooks/company';

import { ActiveLink } from 'components/ActiveLink';

import {
  MenuOptions,
  Content,
  MenuTitle,
  SubMenu,
  SubMenuList,
  SubMenuTitle,
} from './styles';

type SidebarProps = {
  isSidebarExpanded: boolean;
};

export function Menu({ isSidebarExpanded }: SidebarProps) {
  const { menu, handleUpdatingDataRealTime } = useMenu();
  const { companyUser } = useCompany();
  const { pathname } = useLocation();

  const [activeSubMenu, setActiveSubMenu] = useState(false);
  const [menuClassName, setMenuClassName] = useState('');

  function handleActiveSubMenu(item: string) {
    if (isSidebarExpanded) {
      const refactorString = convertStringToClassName(item);

      setMenuClassName(refactorString);

      refactorString === menuClassName
        ? (setActiveSubMenu(false), setMenuClassName(''))
        : setActiveSubMenu(true);
    }
  }

  function convertStringToClassName(item: string) {
    return item.replace(' ', '-').toLowerCase();
  }

  const pathnameFormatted = useMemo(
    () => pathname.replace('/', ''),
    [pathname],
  ).split('/');

  useEffect(() => {
    setActiveSubMenu(false);
    setMenuClassName('');
  }, [isSidebarExpanded]);

  useEffect(() => {
    if (companyUser) handleUpdatingDataRealTime(companyUser.empresa_id);
  }, []);

  return (
    <MenuOptions>
      {menu.length > 0 &&
        menu
          .slice(0, 1)
          .concat(
            menu
              .slice(1, menu.length)
              .sort((i1, i2) => i1.nome.localeCompare(i2.nome)),
          )
          .map(
            ({
              id: idModule,
              nome: nameModule,
              icone: iconModule,
              aplicacao,
            }) => {
              const Icon = (Icons as Record<string, IconType | undefined>)[
                iconModule
              ];

              return (
                <Content
                  className={isSidebarExpanded ? '' : 'content-menu'}
                  key={idModule}
                  isSidebarExpanded={isSidebarExpanded}
                  isSubMenuActive={activeSubMenu}
                  menuClassName={menuClassName}
                >
                  <button
                    type="button"
                    onClick={() => handleActiveSubMenu(nameModule)}
                    className={
                      pathnameFormatted[0] === nameModule.toLowerCase()
                        ? 'menu-selected'
                        : 'menu-select'
                    }
                  >
                    <MenuTitle isSidebarExpanded={isSidebarExpanded}>
                      <span className="menu-icon">{Icon && <Icon />}</span>
                      <span className="menu-title">{nameModule}</span>
                    </MenuTitle>
                    {isSidebarExpanded && (
                      <>
                        {convertStringToClassName(nameModule) ===
                        menuClassName ? (
                          <FiChevronUp className="menu-arrow" />
                        ) : (
                          <FiChevronDown className="menu-arrow" />
                        )}
                      </>
                    )}
                  </button>

                  <SubMenu
                    isSidebarExpanded={isSidebarExpanded}
                    className={convertStringToClassName(nameModule)}
                  >
                    {aplicacao
                      .sort((i1, i2) => i1.nome.localeCompare(i2.nome))
                      .map(app => {
                        const {
                          id: idApplication,
                          caminho: pathApplication,
                          icone: iconApplication,
                          nome: nameApplication,
                        } = app;

                        const IconeApp = (
                          Icons as Record<string, IconType | undefined>
                        )[iconApplication];

                        return (
                          <SubMenuList
                            key={idApplication}
                            isSidebarExpanded={isSidebarExpanded}
                            isSubMenuSelected={pathname === pathApplication}
                          >
                            <ActiveLink
                              to={pathApplication}
                              href={pathApplication}
                            >
                              <SubMenuTitle>
                                {IconeApp && <IconeApp />}
                                <span>{nameApplication}</span>
                              </SubMenuTitle>
                            </ActiveLink>
                          </SubMenuList>
                        );
                      })}
                  </SubMenu>
                </Content>
              );
            },
          )}
    </MenuOptions>
  );
}
