import { useMemo } from 'react';
import { Route, Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';

import { firstCapitalLetter } from 'utils/firstCapitalLetter';

import { Container } from './styles';

interface IParams {
  path: string;
}

interface IRoutes {
  params: IParams;
  isExact: boolean;
  path: string;
  url: string;
}

interface IMatch {
  match: IRoutes;
}

export const Breadcrumbs = () => (
  <Container>
    <Route path="/:path" component={BreadcrumbsItem} />
  </Container>
);

const BreadcrumbsItem = ({ match }: IMatch) => {
  const { isExact, params, url } = match;

  const title = useMemo(() => firstCapitalLetter(params.path), []);

  return (
    <>
      <Link
        className={isExact ? 'breadcrumb-active' : undefined}
        to={url || ''}
      >
        {title}
      </Link>
      {!isExact && <FaAngleRight />}

      <Route path={`${url}/:path`} component={BreadcrumbsItem} />
    </>
  );
};
