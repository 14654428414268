import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const IconWarning = styled.div`
  svg {
    font-size: 4.5rem;
    margin-bottom: 1rem;

    ${({ theme }) => css`
      color: ${theme.colors.lightRed};
    `};
  }
`;

export const Content = styled.div`
  max-width: 360px;
  word-wrap: break-word;
  text-align: center;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;

    ${({ theme }) => css`
      color: ${theme.title === 'dark'
        ? theme.colors.cyan
        : theme.colors.darkBlue};
    `};
  }

  p {
    font-size: 0.8rem;
    line-height: 1rem;
    ${({ theme }) => css`
      color: ${theme.title === 'dark'
        ? theme.colors.ice
        : theme.colors.darkGray};
    `};
  }
`;

export const ButtonsContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button:first-child {
    margin-right: 16px;
  }
`;
