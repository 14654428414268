import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';

import { useTheme } from 'hooks/theme';

import { Container, InputContent, Error, VisiblePassword } from './styles';

interface IInputProps {
  name: string;
  requiredField?: boolean | undefined;
  inputSize?: string;
  icon?: React.ComponentType<IconBaseProps>;
  containerStyle?: React.CSSProperties;
  label?: string;
}

type InputProps = JSX.IntrinsicElements['input'] & IInputProps;

const Input = ({
  name,
  label,
  requiredField,
  inputSize,
  icon: Icon,
  containerStyle = {},
  ...rest
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { theme } = useTheme();

  const { colors } = theme;

  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [inputType, setInputType] = useState<string | undefined>('');
  const [hidePassword, setHidePassword] = useState(true);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    clearError();
  }, [clearError]);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  const visiblePassword = () => {
    setHidePassword(!hidePassword);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    const { type } = { ...rest };
    setInputType(type);
  }, []);

  return (
    <Container>
      {label && (
        <label htmlFor={fieldName}>
          {requiredField && <strong>*</strong>}
          {label}
        </label>
      )}

      <InputContent
        style={containerStyle}
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        inputSize={inputSize}
      >
        {Icon && <Icon className="icon-input" size={20} />}

        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
          type={hidePassword ? inputType : 'text'}
        />
        {inputType === 'password' && (
          <VisiblePassword>
            {hidePassword ? (
              <FiEyeOff onClick={visiblePassword} />
            ) : (
              <FiEye onClick={visiblePassword} />
            )}
          </VisiblePassword>
        )}
        {error && (
          <Error title={error}>
            <FiAlertCircle color={colors.lightRed} size={20} />
          </Error>
        )}
      </InputContent>
    </Container>
  );
};

export default Input;
