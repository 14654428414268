import { useAuth } from 'hooks/auth';
import Skeleton from 'react-loading-skeleton';

import { generateAvatars } from 'utils/generateAvatars';

export function UserAvatar() {
  const { user } = useAuth();

  return (
    <>
      {user ? (
        <img
          src={user.avatar_url ? user.avatar_url : generateAvatars(user?.nome)}
          alt="Avatar do usuário"
        />
      ) : (
        <Skeleton circle width="80%" height="80%" />
      )}
    </>
  );
}
