import { useRef, useEffect, useCallback } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { useField } from '@unform/core';

import { Container } from './styles';

interface ISelectProps extends SelectProps<OptionTypeBase, true> {
  name: string;
  label: string;
  requiredField?: boolean | undefined;
}
export default function Select({
  name,
  label,
  requiredField,
  ...rest
}: ISelectProps) {
  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  const handleInputFocus = useCallback(() => {
    clearError();
  }, [clearError]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }

          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.setValue(value || null);
      },
      clearValue: ref => {
        ref.state.value = '';
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container isErrored={!!error}>
      {label && (
        <label htmlFor={fieldName}>
          {requiredField && <strong>*</strong>}
          {label}
        </label>
      )}

      <ReactSelect
        onFocus={handleInputFocus}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
    </Container>
  );
}
