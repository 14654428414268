import {
  createContext,
  useContext,
  useCallback,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { toast } from 'react-toastify';
import { webBff } from 'services/api';

import { useAuth } from './auth';

interface IPropsChildren {
  children: ReactNode;
}

type Company = {
  avatar_url: string;
  logo_url: string;
};

interface ICompanyUser {
  id: string;
  empresa_id: string;
  favorito: boolean;
  empresa: Company;
}

interface ICompanyState {
  companySelected: ICompanyUser;
}

interface ICompanySelectedContextData {
  companyUser: ICompanyUser;
  addCompanySelected(value: ICompanyState): void;
  removeCompanySelected(): void;
}

const CompanySelectedContext = createContext<ICompanySelectedContextData>(
  {} as ICompanySelectedContextData,
);

const CompanyProvider = ({ children }: IPropsChildren) => {
  const { user, signOut } = useAuth();

  const [data, setData] = useState<ICompanyState>(() => {
    const company = localStorage.getItem('@Insight:company-selected');

    if (company) {
      return { companySelected: JSON.parse(company) };
    }

    return {} as ICompanyState;
  });

  useEffect(() => {
    data.companySelected &&
      (webBff.defaults.headers.companyId = data.companySelected.empresa_id);
  }, [data]);

  useEffect(() => {
    let companySelected;

    if (user) {
      if (data.companySelected) {
        companySelected = user.usuario_empresa.find(
          userCompany =>
            userCompany.empresa_id === data.companySelected.empresa_id,
        );
      } else {
        companySelected = user.usuario_empresa.find(
          userCompany => userCompany.favorito === true,
        );
      }

      if (companySelected) {
        localStorage.setItem(
          '@Insight:company-selected',
          JSON.stringify(companySelected),
        );

        setData({ companySelected });
      } else {
        toast.error(
          'Ocorreu um erro ao tentar encontrar a empresa vinculada, entre em contato com o suporte!',
          { delay: 300 },
        );
        signOut();
      }
    }
  }, [user]);

  useEffect(() => {
    const token = localStorage.getItem('@Insight:token');

    if (!token && data.companySelected) {
      removeCompanySelected();
    }
  }, [user]);

  const addCompanySelected = useCallback(
    ({ companySelected }: ICompanyState) => {
      localStorage.setItem(
        '@Insight:company-selected',
        JSON.stringify(companySelected),
      );

      setData({ companySelected });
    },
    [],
  );

  const removeCompanySelected = useCallback(() => {
    localStorage.removeItem('@Insight:company-selected');

    setData({} as ICompanyState);
  }, []);

  return (
    <CompanySelectedContext.Provider
      value={{
        companyUser: data.companySelected,
        addCompanySelected,
        removeCompanySelected,
      }}
    >
      {children}
    </CompanySelectedContext.Provider>
  );
};

function useCompany(): ICompanySelectedContextData {
  const context = useContext(CompanySelectedContext);

  if (!context) {
    throw new Error('useCompany must be used within a ToastProvider');
  }

  return context;
}

export { CompanyProvider, useCompany };
